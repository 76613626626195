//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Home',
  // data () {
  //   return {
  //     squareBanner: null,
  //     square2Banner: null,
  //     middleBanner: null,
  //     serviceToolsBanner: null
  //   }
  // },
  computed: {
    squareBanner () {
      const squareBanner = this.$content.getHomeBannerLocation('B_FS_SQUARE')
      return squareBanner
    },
    square2Banner () {
      const square2Banner = this.$content.getHomeBannerLocation('B_FS2_SQUARE_2')
      return square2Banner
    },
    middleBanner () {
      const middleBanner = this.$content.getHomeBannerLocation('B_FM_MIDDLE')
      return middleBanner
    },
    serviceToolsBanner () {
      const serviceToolsBanner = this.$content.getHomeBannerLocation(
        'B_INHOUSE_HOME_SERVICE_TOOLS'
      )
      return serviceToolsBanner
    }
  }
  // created () {
  //   this.setBannerData()
  // },
  // methods: {
  //   setBannerData () {
  //     this.squareBanner = this.$content.getHomeBannerLocation('B_FS_SQUARE')
  //     this.square2Banner = this.$content.getHomeBannerLocation('B_FS2_SQUARE_2')
  //     this.middleBanner = this.$content.getHomeBannerLocation('B_FM_MIDDLE')
  //     this.serviceToolsBanner = this.$content.getHomeBannerLocation(
  //       'B_INHOUSE_HOME_SERVICE_TOOLS'
  //     )
  //   }
  // }
}
