import { render, staticRenderFns } from "./LinkOut.vue?vue&type=template&id=14e0b401&scoped=true&"
import script from "./LinkOut.vue?vue&type=script&lang=js&"
export * from "./LinkOut.vue?vue&type=script&lang=js&"
import style0 from "./LinkOut.vue?vue&type=style&index=0&id=14e0b401&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14e0b401",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconBoxArrowUpRight2: require('/app/components/Icon/BoxArrowUpRight2.vue').default})
