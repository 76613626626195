import { render, staticRenderFns } from "./Global.vue?vue&type=template&id=5b4a7041&scoped=true&"
import script from "./Global.vue?vue&type=script&lang=js&"
export * from "./Global.vue?vue&type=script&lang=js&"
import style0 from "./Global.vue?vue&type=style&index=0&id=5b4a7041&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b4a7041",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/app/components/NavTabs/Tab.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,HomeGlobalForeginExchanges: require('/app/components/Pages/Home/Global/ForeginExchanges.vue').default,TabPane: require('/app/components/NavTabs/TabPane.vue').default,HomeGlobalGmsExchanges: require('/app/components/Pages/Home/Global/GmsExchanges.vue').default,HomeGlobalInternationalCooperation: require('/app/components/Pages/Home/Global/InternationalCooperation.vue').default,TabContent: require('/app/components/NavTabs/TabContent.vue').default})
