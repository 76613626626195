//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  formatNumber,
  formatNumberPercent,
  integerStyle
} from '@/helpers/index'
export default {
  name: 'StatisticalData',
  props: {
    quote: {
      type: String,
      default: 'SET'
    },
    index: {
      type: String,
      default: 'SET'
    },
    indexTab: {
      type: String,
      default: 'SET'
    }
  },
  data () {
    return {
      dataStatistic: null,
      fields: {
        statisticAndResult: [
          {
            label: 'ค่าสถิติสำคัญและผลการดำเนินงาน',
            key: 'type',
            thClass: 'text-secondary fs-20px px-0 py-2 heading1-font-family',
            tdClass: 'fs-14px topic-stat'
          },
          {
            label: '',
            key: 'value',
            thClass: 'px-0 py-2',
            tdClass: 'title-font-family text-end fs-20px text-deep-gray'
          }
        ],
        marketChange: [
          {
            label: 'การเปลี่ยนแปลงของดัชนีตลาดหลักทรัพย์',
            key: 'type',
            thClass: 'text-secondary fs-20px px-0 py-2 heading1-font-family',
            tdClass: 'fs-14px  topic-stat'
          },
          {
            label: '',
            key: 'value',
            thClass: 'px-0 py-2',
            tdClass: 'title-font-family text-end fs-20px'
          }
        ]
      }
    }
  },
  // async fetch () {
  //   await this.$axios.get(`api/set/index/${this.quote}/performance`).then((res) => {
  //     this.dataStatistic = res.data
  //   }).catch(err => err)
  // },
  computed: {
    itemsStatistic () {
      let result = []
      if (this.dataStatistic) {
        result = [
          {
            type: 'มูลค่าหลักทรัพย์ตามราคาตลาด (ล้านบาท)',
            value: this.formatNumber(this.dataStatistic.marketCap / 1000000, 2)
          },
          {
            type: 'อัตราหมุนเวียนปริมาณการซื้อขาย (YTD) (%)',
            value: this.formatNumber(this.dataStatistic.ytdTurnoverRatio, 2)
          },
          {
            type: 'P/E (เท่า)',
            value: this.dataStatistic.pe
          },
          {
            type: 'P/BV (เท่า)',
            value: this.dataStatistic.pbv
          },
          {
            type: 'อัตราเงินปันผลตอบแทน (%)',
            value: this.dataStatistic.dividendYield
          }
        ]
      }
      return result
    },
    itemsMarketChange () {
      let result = []
      if (this.dataStatistic) {
        result = [
          {
            type: 'ในรอบ 3 เดือนล่าสุด',
            value: this.dataStatistic.threeMonthPercentChange
          },
          {
            type: 'ในรอบ 6 เดือนล่าสุด',
            value: this.dataStatistic.sixMonthPercentChange
          },
          {
            type: 'YTD (Year to Date)',
            value: this.dataStatistic.ytdPercentChange
          }
        ]
      }
      return result
    }
  },
  async mounted () {
    await this.$axios
      .get(`api/set/index/${this.quote}/performance`)
      .then((res) => {
        this.dataStatistic = res.data
      })
      .catch(err => err)
  },
  methods: {
    formatNumber,
    formatNumberPercent,
    integerStyle,
    goMarketSummary (index) {
      const route = this.$router.resolve({
        path: 'equities/market-summary/overview',
        query: {
          market: index
        }
      })
      window.open(route.href)
    },

    eventTrackingReadMore () {
      this.$personalized.clickElement({
        name: 'home7',
        optionLang: {
          market: this.indexTab
        }
      })
    }
  }
}
