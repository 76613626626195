//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  integerTradingviewStyle,
  formatNumber,
  formatNumberInteger,
  formatNumberPercent
} from '@/helpers/index'
import { formatDateTimeLong } from '@/helpers/dateTime'
import { convertKeyRankingTypeToPath } from '@/helpers/utilities'
export default {
  name: 'HomeGlobalForeginExchanges',
  props: {
    quote: {
      type: String,
      default: 'SET'
    },
    showWidget: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      Thomson: null,
      ThomsonDate: null,
      Exchange: [
        {
          symbol: 'SSE',
          exchangeSymbol: 'SSE',
          exchangeName: 'SSE',
          last: '3,540.38',
          change: +25.91,
          percentChange: +0.74,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'SZSE',
          exchangeSymbol: 'SZSE',
          exchangeName: 'SZSE',
          last: '14,697.50',
          change: +33.95,
          percentChange: +0.23,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        }
      ]
    }
  },
  async fetch () {
    await this.$axios
      .get('api/set/foreign-index/list')
      .then((res) => {
        this.ThomsonDate = res.data.datetime
        this.Thomson = res.data.indices.filter(
          y => y.symbol === 'TOPIX' || y.symbol === 'IDX'
        )
      })
      .catch(err => err)
  },
  methods: {
    integerTradingviewStyle,
    formatNumberInteger,
    formatNumberPercent,
    formatNumber,
    formatDateTimeLong,
    convertKeyRankingTypeToPath,
    goForeignExchange () {
      window.open('https://www.investing.com/indices/world-indices', '_blank')
    },

    eventTrackingReadMore () {
      this.$personalized.clickElement({
        name: 'home16'
      })
    }
  }
}
