//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { integerTradingviewStyle } from '@/helpers/index'
export default {
  name: 'HomeGlobalGmsExchanges',
  props: {
    quote: {
      type: String,
      default: 'SET'
    }
  },
  data () {
    return {
      Gms: null
    }
  },
  async fetch () {
    await this.$axios.get('api/set/foreign-index/gms/indices').then((res) => {
      // console.log('this.Gms', this.Gms)
      this.Gms = res.data
      // console.log('this.Gms', this.Gms)
    }).catch(err => err)
  },
  methods: {
    integerTradingviewStyle,
    gotoGMS () {
      window.open('https://www.set.or.th/th/market/index/gms-exchanges/trading', '_blank')
    },
    eventTrackingReadMore () {
      this.$personalized.clickElement({
        name: 'home17'
      })
    }
  }
}
