//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { hasValue } from '@/helpers/index'
import { formatDateShort, formatTimeShort } from '@/helpers/dateTime'
import { handleImageError } from '@/helpers'
export default {
  name: 'HomeListedCompanyOpportunityDay',
  data () {
    return {
      swiperOption: {
        showPagination: false,
        showNavigation: true,
        resizeObserver: true,
        pagination: {
          el: '.opportunity-day .swiper-pagination',
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"></span>'
          }
        },
        navigation: {
          nextEl: '.opportunity-day .swiper-btn-next',
          prevEl: '.opportunity-day .swiper-btn-prev'
        },
        showNavigationOutSide: true,
        loop: false,
        slidesPerView: 1,
        slidesPrrGroup: 1,
        spaceBetween: 0,
        breakpoints: {
          992: {
            slidesPerView: 2,
            slidesPerGroup: 1
          }
        }
      },
      loading: true,
      items: [],
      allItems: []
    }
  },
  async fetch () {
    // await this.fetchData()
  },
  computed: {
    swiper () {
      return this.$refs.carouselOppday?.swiper
    }
  },
  async mounted () {
    await this.fetchData()
    this.$nextTick(() => {
      this.initTooltip()
      this.onSwiperInit()
    })
  },

  updated () {
    this.$nextTick(() => {
      this.initTooltip()
    })
  },

  destroyed () {
    this.destroyTooltip()
  },
  methods: {
    hasValue,
    formatDateShort,
    formatTimeShort,
    playerUrl (url) {
      return url ?? '#'
    },
    handleError (event, imgUrl) {
      const option = { src: imgUrl, ratio: '1x1' }
      const target = event.currentTarget
      handleImageError(target, option)
      target.classList.remove('border')
    },
    triggerActive (index) {
      if (Object.keys(this.items[index].profile).length === 0) {
        this.loadMoreOppday(index)
      }
    },
    onSwiperInit () {
      if (this.swiper) {
        this.swiper.on('breakpoint', (e) => {
          this.swiper.update()
        })
        // this.swiper.on('sliderMove', () =)
        // this.swiper.on('slideNextTransitionStart', () => {
        //   if (this.swiper.activeIndex + 1 < this.items.length - 1) {
        //     if (Object.keys(this.items[this.swiper.activeIndex + 2].profile).length === 0) {
        //       this.loadMoreOppday(this.swiper.activeIndex + 2)
        //     }
        //   }
        // })
      }
    },
    async getOppday (status) {
      const option = {
        params: {
          status
        }
      }
      const response = await this.$axios.get('/api/set-content/vdo/oppday/list', option)
        .then(res => res)
        .catch(err => err)
      if (response?.status === 200) {
        return response?.data ?? []
      }
      return []
    },
    async fetchData () {
      this.setloading(true)
      const oppDayList = await Promise.allSettled([
        this.getOppday('LIVE'),
        this.getOppday('UPCOMING')
      ])
      const liveList = oppDayList[0].value
      let liveOrArchiveList = liveList
      if (!liveList.length) {
        liveOrArchiveList = await this.getOppday('ARCHIVE')
      }

      // union all
      const dataOppDayList = [
        ...liveOrArchiveList, // LIVE OR ARCHIVE
        ...oppDayList[1].value // UPCOMING
      ]

      this.allItems = dataOppDayList.map(item => ({
        ...item,
        profile: {}
      }))
      this.items = this.allItems
      if (this.items.length > 0) {
        if (this.items.length > 3) {
          const promises = this.allItems.slice(0, 3).map(async (item, index) => {
            const data = await this.mapProfileData(item)
            this.items[index].profile = data
          })
          await Promise.all(promises)
        } else if (this.items.length <= 3) {
          const promises = this.items.map(async (item, index) => {
            const data = await this.mapProfileData(item)
            this.items[index].profile = data
          })
          await Promise.all(promises)
        }
      }
      if (this.items.length > 10) {
        this.items = this.items.slice(0, 10)
      }
      this.setloading(false)
    },
    async loadMoreOppday (index) {
      const data = await this.mapProfileData(this.items[index])
      this.items[index].profile = data
      // this.swiper.update()
    },
    async mapProfileData (oppday) {
      const profile = await this.getProfile(oppday.symbol)
      const data = {
        logo: hasValue(oppday.logoUrl)
          ? oppday.logoUrl
          : '/images/logo/defaultImg.png',
        symbol: oppday.symbol,
        companyName: oppday.companyNameEN,
        market: profile?.market,
        industryName: profile?.industryName,
        sectorName: profile?.sectorName,
        date: formatDateShort(oppday.startDatetime),
        startTime: formatTimeShort(oppday.startDatetime),
        endTime: formatTimeShort(oppday.endDatetime),
        status: oppday.status
      }
      // console.log('map profile', data)
      return data
    },
    async getProfile (symbol) {
      const response = await this.$axios.get(`/api/set/stock/${symbol}/profile`)
        .then(res => res)
        .catch(err => err)
      if (response?.status === 200) {
        const { data } = response
        return data
      }
      return null
    },
    GoOppDayDetail (type) {
      let link = ''
      if (type === 'LIVE' || type === 'ARCHIVE') {
        link = 'https://www.set.or.th/oppday'
      } else {
        link = 'https://listed-company-presentation.setgroup.or.th/calendar'
      }
      window.open(link)
    },
    gotoQuote (symbol) {
      this.$router.push(`/th/equities/quote/${symbol}/overview`)
    },
    initTooltip () {
      const { Tooltip } = require('bootstrap')
      const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      )
      tooltipTriggerList.forEach(
        tooltipTriggerEl => new Tooltip(tooltipTriggerEl, {
          customClass: 'custom-tooltip'
        }))
    },

    destroyTooltip () {
      const { Tooltip } = require('bootstrap')
      const tooltipTriggerList =
        document.querySelectorAll('[data-bs-toggle="tooltip"]') || []
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        const tooltipEle = Tooltip.getOrCreateInstance(tooltipTriggerEl)
        tooltipEle.dispose()
      })
    },
    mergName (industryName, sectorName) {
      return industryName + ' / ' + sectorName
    },
    setloading (el) {
      this.loading = el
    },
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
  }
}
