import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=2bb8b9b8&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style0 from "./Home.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoverHome: require('/app/components/Cover/Home.vue').default,HomeIndexSet: require('/app/components/Pages/Home/IndexSet.vue').default,HomeMostActiveVolume: require('/app/components/Pages/Home/MostActiveVolume.vue').default,PlaceholderCardImage: require('/app/components/Placeholder/CardImage.vue').default,HomeMarketMovementSlide: require('/app/components/Pages/Home/MarketMovement/MarketMovementSlide.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,Banner: require('/app/components/Banner/Banner.vue').default,HomeMarketMovement: require('/app/components/Pages/Home/MarketMovement/MarketMovement.vue').default,PlaceholderList: require('/app/components/Placeholder/List.vue').default,Placeholder: require('/app/components/Placeholder/Placeholder.vue').default,HomeAnalyst: require('/app/components/Pages/Home/Analyst/Analyst.vue').default,HomeNewsAndEconomic: require('/app/components/Pages/Home/NewsAndAnalyst/NewsAndEconomic.vue').default,HomeStockComAndScreen: require('/app/components/Pages/Home/Stock/StockComAndScreen.vue').default,Button: require('/app/components/Button/Button.vue').default,HomeListedCompany: require('/app/components/Pages/Home/ListedCompany/ListedCompany.vue').default})
