//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import {
  integerStyle,
  formatNumberInteger,
  formatNumberPercent,
  integerLevelStyle
} from '@/helpers/index'
export default {
  name: 'HomeTfex',
  props: {
    marketSummaryData: {
      type: Object,
      default: () => {}
    },
    indexTab: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isTablet: this.$device.isTablet,
      scrolled: false,
      showHintScroll: false,
      addEventAlready: false,
      futureSelected: '',
      optionSelected: 'options-call'
    }
  },
  computed: {
    ...mapGetters({
      marketHomeTfex: 'home/getMarketHomeTfex'
    }),
    marketTfex () {
      return this.marketHomeTfex.filter((r) => { return r !== null })
    }
  },
  updated () {
    this.$nextTick(() => {
      this.checkDeviceSize()
      this.registerEvent()
      this.handleHint()
    })
  },
  mounted () {},
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    integerStyle,
    formatNumberInteger,
    formatNumberPercent,
    integerLevelStyle,
    tabsSlideInit (swiper) {
      swiper.on('slideChange', () => {
      })
    },
    registerEvent () {
      const element = this.$refs.indexTfexStockWrapper
      if (element && !this.addEventAlready) {
        element.addEventListener('scroll', this.handleScroll)
        this.addEventAlready = true
      }
      window.addEventListener('resize', this.checkDeviceSize)
    },
    removeEvent () {
      const element = this.$refs.indexTfexStockWrapper
      if (element) {
        element.removeEventListener('scroll', this.handleScroll)
      }
      window.removeEventListener('resize', this.checkDeviceSize)
    },
    checkDeviceSize () {
      const wInnerWidth = window.innerWidth
      const { deviceSizes } = require('~/config/core')
      if (wInnerWidth <= deviceSizes.lg) {
        this.isTablet = true
      } else {
        this.isTablet = false
      }
    },
    handleScroll () {
      if (this.scrolled) {
        return
      }
      this.scrolled = true
    },
    handleHint () {
      if (!this.$refs.indexTfexStockTabs) {
        return
      }
      const indexTfexStockWrapper = this.$refs.indexTfexStockWrapper
      const indexTfexStockTabs = this.$refs.indexTfexStockTabs.$el
      const wrapperH = indexTfexStockWrapper.clientHeight
      const innerH = indexTfexStockTabs.clientHeight
      if (this.scrolled) {
        setTimeout(() => {
          this.showHintScroll = false
        }, 200)
      } else {
        this.showHintScroll = innerH > wrapperH && this.scrolled === false
      }
    },
    changeMarketTab (item) {
      this.$emit('changeTfexTab', item, 'TFEX')
    }
  }
}
