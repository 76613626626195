//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
import { formatNumber } from '@/helpers/number'

export default {
  name: 'Chart2LineMarket',
  props: {
    dataObj: {
      type: Object,
      default: Object
    },
    hasNightSession: {
      type: Boolean,
      default: false
    },
    legendItem: {
      type: Array,
      default: () => [
        {
          label: 'S50V22',
          color: '#3056BA'
        }
      ]
    }
  },
  data () {
    return {
      chart: null,
      am5: null,
      isShowSet50: true
    }
  },

  computed: {
    renderChartData () {
      return cloneDeep(this.dataObj) || []
    }
  },

  watch: {
    renderChartData (renderChartData) {
      this.clearChart()
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },

  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },

  methods: {
    clearChart () {
      if (this.chart) {
        this.chart.dispose()
        this.init()
      }
    },
    init () {
      const prior = this.renderChartData.prior
      const data = this.renderChartData.quotations
        .sort((a, b) => new Date(a.localDatetime) - new Date(b.localDatetime))
        .map((item, index) => {
          // return { ...item, datetime: new Date(item.datetime) }
          return {
            ...item,
            price: item.price === null && index === 0 ? prior : item.price,
            datetime: new Date(item.localDatetime).getTime(),
            priceLabel: formatNumber(item.price)
          }
        })
      // const chartDate = chartData[0].datetime
      const { am5, am5xy } = require('@/plugins/amchart5')
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      const root = am5.Root.new(this.$refs.chartdiv)
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      // root.setThemes([am5ThemesAnimated.new(root)])
      root.numberFormatter.setAll({
        numberFormat: '#,###.00',
        numericFields: ['valueY']
      })
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          paddingTop: 25,
          // paddingRight: 0,
          // paddingLeft: 10,
          paddingBottom: 50
          // maxTooltipDistance: -1
        })
      )
      // disabled chart zoom
      chart.zoomOutButton.set('forceHidden', true)

      // make y axes stack
      chart.leftAxesContainer.set('layout', root.verticalLayout)

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none',
          fontSize: 2
        })
      )
      // cursor.lineY.set('visible', false)
      // cursor.lineX.setAll({
      //   stroke: am5.color('#0A57C8'),
      //   strokeWidth: 2,
      //   strokeDasharray: [3, 3]

      // })

      // <--------- xAxis ---------->

      const tooltipDate = am5.Tooltip.new(root, {})

      tooltipDate.get('background').setAll({
        fill: am5.color('#1D3352'),
        fillOpacity: 1
      })

      const xRenderer = am5xy.AxisRendererX.new(root, {
        inside: false,
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: am5.color('#3F444A'),
        minGridDistance: 30
      })

      xRenderer.grid.template.setAll({
        location: 0,
        multiLocation: 0,
        visible: false
      })

      xRenderer.ticks.template.setAll({
        visible: true,
        strokeWidth: 1,
        length: 5,
        location: 0
      })

      xRenderer.labels.template.setAll({
        fontFamily: 'Sarabun, sans-serif',
        textAlign: 'center',
        paddingTop: 10,
        fontSize: 12,
        location: 0
      })

      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          // categoryField: 'datetime',
          tooltipDateFormat: 'HH:mm:ss',
          maxDeviation: 0.3,
          // startLocation: 0.5,
          // endLocation: 0.5,
          // extraMin: -0.1,
          baseInterval: {
            timeUnit: 'minute',
            count: 1
          },
          markUnitChange: false,
          gridIntervals: this.hasNightSession
            ? [{ timeUnit: 'hour', count: 4 }]
            : [{ timeUnit: 'hour', count: 2 }],
          renderer: xRenderer,
          tooltip: tooltipDate
        })
      )

      // <--------- YAxis ---------->

      const yRenderer = am5xy.AxisRendererY.new(root, {
        inside: false,
        opposite: true
      })

      yRenderer.ticks.template.setAll({
        visible: false
      })

      yRenderer.grid.template.setAll({
        stroke: am5.color('#DEDEDE'),
        strokeOpacity: 1,
        strokeWidth: 1
      })

      yRenderer.labels.template.setAll({
        fontFamily: 'Sarabun, sans-serif',
        fontSize: 12,
        textAlign: 'center'
      })

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // maxDeviation: 0.2,
          baseValue: prior,
          renderer: yRenderer
          // maxPrecision: 0
          // strictMinMax: true
        })
      )

      root.numberFormatter.set('numberFormat', '#,###.00')

      yAxis.set(
        'numberFormatter',
        am5.NumberFormatter.new(root, {
          numberFormat: '#,###.00'
        })
      )

      this.addSeries(chart, am5xy, am5, root, xAxis, yAxis, data, prior)

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      // series.appear(1000)
      // chart.appear(1000, 100)
      // this.am5 = am5
      this.chart = root
    },

    addSeries (chart, am5xy, am5, root, xAxis, yAxis, data, prior) {
      this.legendItem.forEach((item, index) => {
        // if (am5.registry.entitiesById && this.am5.registry.entitiesById[item.label]) {
        //   am5.registry.entitiesById[item.label].dispose()
        // }
        const series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: item.label,
            // id: item.label,
            xAxis,
            yAxis,
            // valueYField: `value${(index + 1)}`,
            valueYField: 'price',
            valueXField: 'datetime',
            stroke: am5.color('#16BD45')
          })
        )

        series.strokes.template.setAll({
          strokeWidth: 1
        })

        series.fills.template.setAll({
          visible: true,
          opacity: 1
        })

        series.fills.template.set(
          'fillGradient',
          am5.LinearGradient.new(root, {
            stops: [
              {
                color: am5.color('#17AF54'),
                opacity: 0.4
              },
              {
                color: am5.color('#17AF54'),
                opacity: 0.2
              },
              {
                color: am5.color('#17AF54'),
                opacity: 0
              }
            ],
            rotation: 0
          })
        )

        this.addTooltip(root, am5, series)

        // <------------------ range -----------> //

        const rangePriorItem = yAxis.makeDataItem({
          value: prior,
          above: true
        })

        const rangePrior = yAxis.createAxisRange(rangePriorItem)

        rangePrior.get('grid').setAll({
          stroke: am5.color('#396478'),
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeDasharray: [2, 2],
          visible: true
        })

        // rangePrior.fills.template.setAll({
        //   visible: true,
        //   opacity: 1
        // })

        // rangePriorItem.get('grid').setAll({
        //   stroke: am5.color('#396478'),
        //   strokeWidth: 1,
        //   strokeOpacity: 1,
        //   strokeDasharray: [2, 2],
        //   visible: true
        // })

        // rangePriorItem.get('label').setAll({
        //   inside: true
        // })

        const rangeDataItem = yAxis.makeDataItem({
          value: prior,
          endValue: 0,
          affectsMinMax: true
        })

        const rangeData = series.createAxisRange(rangeDataItem)

        rangeData.strokes.template.setAll({
          stroke: am5.color('#EC3E3E'),
          strokeWidth: 1
        })

        rangeData.fills.template.setAll({
          visible: true,
          fillOpacity: 1
        })

        rangeData.fills.template.set(
          'fillGradient',
          am5.LinearGradient.new(root, {
            stops: [
              {
                color: am5.color('#EC3E3E'),
                opacity: 0.4
              },
              {
                color: am5.color('#EC3E3E'),
                opacity: 0.2
              },
              {
                color: am5.color('#EC3E3E'),
                opacity: 0
              }
            ],
            rotation: 0
          })
        )

        series.data.setAll(data)
        // series.appear(1000)
      })
    },

    // addLineLastPricePeriod (yAxis, series, am5) {
    //   const seriesRangeDataItemY = yAxis.makeDataItem({
    //     value: 1000,
    //     endValue: 0
    //   })
    //   const seriesRange = series.createAxisRange(seriesRangeDataItemY)
    //   seriesRange.fills.template.setAll({
    //     visible: true,
    //     opacity: 1
    //   })

    //   seriesRangeDataItemY.get('grid').setAll({
    //     strokeOpacity: 1,
    //     visible: true,
    //     stroke: am5.color('#545557'),
    //     strokeDasharray: [4, 4]
    //   })
    // },

    // hideSerie (symbol) {
    //   if (this.am5.registry.entitiesById) {
    //     const series = this.am5.registry.entitiesById[symbol]
    //     if (!series.isHidden()) {
    //       this.isShowSet50 = false
    //       series.hide()
    //     } else {
    //       this.isShowSet50 = true
    //       series.show()
    //     }
    //   }
    // },

    addTooltip (root, am5, series) {
      // add tooltip
      const tooltipHTML = '<div class="tooltip-custom">{priceLabel}</div>'
      const tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        autoTextColor: false,
        pointerOrientation: 'vertical',
        labelHTML: tooltipHTML
      })

      tooltip.get('background').setAll({
        fill: am5.color('#FFFFFF'),
        fillOpacity: 1,
        stroke: am5.color('#DEE4E8'),
        strokeWidth: 2
      })

      series.set('tooltip', tooltip)

      // series.get('tooltip').label.adapters.add('text', function (fill, target) {
      //   const tooltipEle = document.querySelector('.tooltip-custom')
      //   console.log('🚀 ~ tooltipEle:', tooltipEle)
      // })
    }
  }
}
