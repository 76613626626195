//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  integerStyle,
  formatNumber,
  formatNumberInteger,
  formatNumberPercent
} from '@/helpers/index'
import { formatDateTimeLong } from '@/helpers/dateTime'
import { convertKeyRankingTypeToPath } from '@/helpers/utilities'
export default {
  name: 'HomeGlobal',
  props: {
    marketSummaryData: {
      type: Object,
      default: () => {}
    },
    indexTab: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      scrolled: false,
      showHintScroll: false,
      addEventAlready: false,
      internationalCooperation: false,
      foreignExchanges: false,
      futureSelected: '',
      optionSelected: 'options-call',
      Thomson: [
        {
          symbol: 'TOPIX',
          exchangeSymbol: 'TOPIX',
          exchangeName: 'TOPIX',
          last: '1,934.25',
          change: -4.75,
          percentChange: -0.24,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'KOSPI',
          exchangeSymbol: 'KOSPI',
          exchangeName: 'KOSPI',
          last: '3,146.81',
          change: +8.51,
          percentChange: +0.27,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'HSI',
          exchangeSymbol: 'HSI',
          exchangeName: 'HSI',
          last: '25,693.95',
          change: -33.97,
          percentChange: -0.13,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'TAIEX',
          exchangeSymbol: 'TAIEX',
          exchangeName: 'TAIEX',
          last: '17,045.86',
          change: +227.13,
          percentChange: +1.35,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'NIFTY50',
          exchangeSymbol: 'NIFTY50',
          exchangeName: 'NIFTY50',
          last: '16,634.65',
          change: +10.05,
          percentChange: +0.06,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'IDX',
          exchangeSymbol: 'IDX',
          exchangeName: 'IDX',
          last: '6,113.24',
          change: +23.74,
          percentChange: +0.39,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        }
      ],
      Exchange: [
        {
          symbol: 'SSE',
          exchangeSymbol: 'SSE',
          exchangeName: 'SSE',
          last: '3,540.38',
          change: +25.91,
          percentChange: +0.74,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'SZSE',
          exchangeSymbol: 'SZSE',
          exchangeName: 'SZSE',
          last: '14,697.50',
          change: +33.95,
          percentChange: +0.23,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        }
      ],
      Gms: [
        {
          symbol: 'SET',
          exchangeSymbol: 'SET',
          exchangeName: 'SET',
          last: '1,591.84',
          change: +1.03,
          percentChange: +0.06,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'CSX',
          exchangeSymbol: 'CSX',
          exchangeName: 'CSX',
          last: '521.64',
          change: +0.45,
          percentChange: +0.09,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'HNX',
          exchangeSymbol: 'HNX',
          exchangeName: 'HNX',
          last: '336.01',
          change: +4.22,
          percentChange: +1.27,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'LSX',
          exchangeSymbol: 'LSX',
          exchangeName: 'LSX',
          last: '624.60',
          change: +11.10,
          percentChange: +1.81,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'MYANPIX',
          exchangeSymbol: 'MYANPIX',
          exchangeName: 'MYANPIX',
          last: '400.83',
          change: 0.00,
          percentChange: 0.00,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        },
        {
          symbol: 'VNI',
          exchangeSymbol: 'VNI',
          exchangeName: 'VNI',
          last: '1,309.55',
          change: +10.81,
          percentChange: +0.83,
          high: 'TOPIX',
          low: 'TOPIX',
          value: 'TOPIX',
          volume: 'TOPIX',
          remark: 'TOPIX',
          currency: 'TOPIX'
        }
      ]
    }
  },
  mounted () {
    setTimeout(() => {
      this.foreignExchanges = true
    }, 200)
  },
  methods: {
    integerStyle,
    formatNumberInteger,
    formatNumberPercent,
    formatNumber,
    formatDateTimeLong,
    convertKeyRankingTypeToPath,
    registerEvent () {
      const element = this.$refs.indexTfexStockWrapper
      if (element && !this.addEventAlready) {
        element.addEventListener('scroll', this.handleScroll)
        this.addEventAlready = true
      }
    },
    initTab () {
      this.$nextTick(() => {
        setTimeout(() => {
          this.activeTabGlobalByHash()
        }, 200)
      })
    },
    activeTabGlobalByHash () {
      const hash = window.location.hash
      if (hash) {
        const hashAfterReplace = hash?.replace('#', '')
        const hashTab = hashAfterReplace.slice(hashAfterReplace.indexOf('-') + 1)
        const elementTabGlobal = document.getElementById(hashTab)
        elementTabGlobal?.click()
      }
    },
    handleInternationalShown () {
      this.internationalCooperation = true
    },
    handleForeignExchangesShown () {
      this.foreignExchanges = true
    }
  }
}
