//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { integerStyle, integerLevelStyle, openTab } from '@/helpers/index'
import { formatDateTimeLong } from '@/helpers/dateTime'
export default {
  name: 'CardMarketIndex',
  props: {
    marketSummaryData: {
      type: Object,
      default: Object
    },
    market: {
      type: String,
      default: ''
    }
  },
  // mounted () {
  //   console.log('marketSummaryData', JSON.parse(
  //     JSON.stringify(this.marketSummaryData)
  //   ))
  // },
  methods: {
    formatDateTimeLong,
    integerStyle,
    integerLevelStyle,
    gotoTfexQuote () {
      openTab(`derivatives/quote/${this.marketSummaryData.symbol}/overview`)
    }
  }
}
