//
//
//
//
//

export default {
  name: 'ChartLoading',
  props: {
    position: {
      type: String,
      default: 'relative'
    }
  }
}
