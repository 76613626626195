//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import { integerStyle, integerLevelStyle, numberColor, openTab } from '@/helpers/index'
export default {
  name: 'InvestorsTypeSummary',
  props: {
    indexTab: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tabActive: 'futures',
      fields: [
        {
          label: 'นักลงทุน',
          key: 'type',
          thClass: 'ps-4 fw-500 w-30',
          tdClass: 'ps-4'
        },
        {
          label: 'ซื้อ',
          key: 'buyValue',
          thClass: 'text-end fw-500',
          tdClass: 'text-end'
        },
        {
          label: 'ขาย',
          key: 'sellValue',
          thClass: 'text-end fw-500',
          tdClass: 'text-end'
        },
        {
          label: 'สุทธิ',
          key: 'netValue',
          thClass: 'text-end pe-4 fw-500',
          tdClass: (value, key, item) => {
            return (
              'text-end pe-4 ' +
              this.integerStyle(item.netValue)
            )
          }
        }
      ],
      itemsFutures: [],
      itemsOptions: [],
      optionOption: [{ name: 'Call Options', value: 'options-call' }, { name: 'Put Options', value: 'options-put' }],
      futureSelected: 'all',
      optionSelected: 'options-call',
      dateFuture: '',
      dateOption: ''
    }
  },
  computed: {
    ...mapState({
      futureOption: state => state.home.marketHome.tfexFutureOption,
      futureInvestorVolume: state => state.home.marketHome.tfexFutureInvestorVolume,
      optionInvestorVolume: state => state.home.marketHome.tfexOptionInvestorVolume
    }),
    futureItem () {
      return this.itemsFutures
    },
    optionItem () {
      return this.itemsOptions
    },
    futureItemOption () {
      return this.futureOption || []
    }
  },
  async mounted () {
    await this.getTFEXFutureOption()
    await this.getTFEXFutureInvestorVolume('')
    this.dateFuture = this.futureInvestorVolume.date
    this.itemsFutures = this.futureInvestorVolume.item
    await this.getTFEXOptionInvestorVolume(this.optionSelected)
    this.dateOption = this.optionInvestorVolume.date
    this.itemsOptions = this.optionInvestorVolume.item
  },
  methods: {
    integerStyle,
    integerLevelStyle,
    numberColor,
    openTab,
    ...mapActions('home', ['getTFEXFutureOption', 'getTFEXFutureInvestorVolume', 'getTFEXOptionInvestorVolume']),
    async dropdownSelected (item) {
      if (this.tabActive === 'futures') {
        this.futureSelected = item
        await this.getTFEXFutureInvestorVolume(item === 'all' ? '' : item)
        this.itemsFutures = this.futureInvestorVolume.item
      } else {
        this.optionSelected = item
        await this.getTFEXOptionInvestorVolume(item)
        this.itemsOptions = this.optionInvestorVolume.item
      }
    },

    eventTrackingReadMore () {
      this.$personalized.clickElement({
        name: this.tabActive === 'futures' ? 'home13' : 'home14',
        optionLang: {
          market: this.indexTab
        }
      })
    }
  }
}
