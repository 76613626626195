import { render, staticRenderFns } from "./InvestorsTypeSummary.vue?vue&type=template&id=3e096d29&"
import script from "./InvestorsTypeSummary.vue?vue&type=script&lang=js&"
export * from "./InvestorsTypeSummary.vue?vue&type=script&lang=js&"
import style0 from "./InvestorsTypeSummary.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/app/components/NavTabs/Tab.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,DropdownItem: require('/app/components/Dropdown/Item.vue').default,Dropdown: require('/app/components/Dropdown/Dropdown.vue').default,IconClock: require('/app/components/Icon/Clock.vue').default,TableCustomField: require('/app/components/Table/TableCustomField.vue').default,TabPane: require('/app/components/NavTabs/TabPane.vue').default,TabContent: require('/app/components/NavTabs/TabContent.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default})
