//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getMapPathName, getCateParamResearch } from '@/helpers/categoryParams'
export default {
  name: 'AnalystCardArticle',
  props: {
    article: {
      type: Object,
      default: Object
    }
  },
  computed: {
    cateUrl () {
      const query = {
        category: getCateParamResearch(this.article.cateUuid)
      }
      const item = this.localePath({
        name: 'research-analyst-research-main',
        query
      })
      return item
    },
    symbolUrl () {
      let url = ''
      if (this.article.cateUuid && this.article.symbol) {
        url = this.localePath({
          name: getMapPathName(this.article.cateUuid),
          params: { slug: this.article.symbol, pathMatch: 'overview' }
        })
      }
      return url
    }
  },
  methods: {
    getMapPathName,
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
  }
}
