//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { numberColor, openTab } from '~/helpers/index'
export default {
  name: 'HomeFTSESET',
  props: {
    ftseList: {
      type: Array,
      default: Array
    }
  },
  computed: {
    ...mapGetters({
      ftseSetData: 'home/getFtseSet'
    }),
    FTSElistIndexList () {
      // console.log('FTSElistIndex=>>', this.ftseSetData)
      return this.ftseSetData
    },
    setWebsiteUrl () {
      return process.env.SET_WEBSITE_URL
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions({
      getFtseSet: 'home/getFtseSet'
    }),
    async init () {
      await this.getFtseSet()
    },
    numberColor,
    openTab,
    routePathOverview () {
      // this.$router.push(
      //   this.localePath({
      //     name: 'market-index-slug-overview',
      //     params: { slug: 'ftse-set' }
      //   })
      // )
      const url = this.localePath({
        name: 'market-index-slug-overview',
        params: { slug: 'ftse-set' }
      })
      return url || ''
    },
    gotoFTSESET () {
      window.open(
        process.env.SET_WEBSITE_URL + 'th/market/index/ftse-set/overview',
        '_blank'
      )
    },

    eventTrackingReadMore () {
      this.$personalized.clickElement({
        name: 'home8',
        optionLang: {
          market: 'SET',
          title: 'FTSE SET Index Series'
        }
      })
    }
  }
}
