var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"market-summary-index pt-2 pb-1"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"d-flex flex-column col-12 col-md-6 pe-md-3"},[_c('CardMarketIndex',_vm._b({},'CardMarketIndex',_vm.$props,false)),_vm._v(" "),(_vm.market !== 'TFEX')?_c('div',{staticClass:"market-summary-index-items d-flex flex-wrap"},[_c('div',{staticClass:"d-none d-md-flex flex-column col-5 me-auto"},[_c('div',{staticClass:"d-flex flex-column border-bottom py-3"},[_c('label',[_vm._v("สูงสุด")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.marketSummaryData ? _vm.marketSummaryData.high : 0)))])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column py-3"},[_c('label',[_vm._v("ปริมาณ ('000 หุ้น)")]),_vm._v(" "),(_vm.marketSummaryData.volume)?_c('span',[_vm._v(_vm._s(_vm.marketSummaryData
                ? _vm.formatNumber(_vm.marketSummaryData.volume / 1000, 0)
                : 0))]):_c('span',[_vm._v("-")])])]),_vm._v(" "),_c('div',{staticClass:"d-none d-md-flex flex-column col-5"},[_c('div',{staticClass:"d-flex flex-column border-bottom py-3"},[_c('label',[_vm._v("ต่ำสุด")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.marketSummaryData ? _vm.marketSummaryData.low : 0)))])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column py-3"},[_c('label',[_vm._v("มูลค่า (ล้านบาท)")]),_vm._v(" "),(_vm.marketSummaryData.value)?_c('span',[_vm._v(_vm._s(_vm.marketSummaryData
                ? _vm.formatNumber(_vm.marketSummaryData.value / 1000000, 2)
                : 0))]):_c('span',[_vm._v("-")])])]),_vm._v(" "),_c('div',{staticClass:"d-flex d-md-none w-100"},[_c('div',{staticClass:"d-flex flex-column border-end my-3 me-1",staticStyle:{"flex":"1 1 auto"}},[_c('label',[_vm._v("สูงสุด")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.marketSummaryData ? _vm.marketSummaryData.high : 0)))])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column border-end my-3 me-1 ps-2",staticStyle:{"flex":"1 1 auto"}},[_c('label',[_vm._v("ต่ำสุด")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.marketSummaryData ? _vm.marketSummaryData.low : 0)))])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column border-end my-3 me-1 ps-2",staticStyle:{"flex":"1 1 auto"}},[_c('label',[_vm._v("ปริมาณ ('000 หุ้น)")]),_vm._v(" "),(_vm.marketSummaryData.volume)?_c('span',[_vm._v(_vm._s(_vm.marketSummaryData
                ? _vm.formatNumber(_vm.marketSummaryData.volume / 1000, 0)
                : 0))]):_c('span',[_vm._v("-")])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column my-3 ps-2",staticStyle:{"flex":"1 1 auto"}},[_c('label',[_vm._v("มูลค่า (ล้านบาท)")]),_vm._v(" "),(_vm.marketSummaryData.value)?_c('span',[_vm._v(_vm._s(_vm.marketSummaryData
                ? _vm.formatNumber(_vm.marketSummaryData.value / 1000000, 2)
                : 0))]):_c('span',[_vm._v("-")])])])]):_c('div',{staticClass:"market-summary-index-items d-flex flex-wrap"},[_c('div',{staticClass:"d-flex flex-column col-4 me-auto pe-2"},[_c('div',{staticClass:"d-flex flex-column border-bottom py-3"},[_c('label',[_vm._v("ราคาเปิด")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.marketSummaryData
                ? _vm.marketSummaryData.bidPrice
                : 0)))])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column py-3"},[_c('label',[_vm._v("ราคาเฉลี่ย")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.marketSummaryData ? _vm.marketSummaryData.average : 0)))])])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column col-4 me-auto pe-2"},[_c('div',{staticClass:"d-flex flex-column border-bottom py-3"},[_c('label',[_vm._v("ราคาสูงสุด")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.marketSummaryData ? _vm.marketSummaryData.high : 0)))])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column py-3"},[_c('label',[_vm._v("ปริมาณ (สัญญา)")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.marketSummaryData
                ? _vm.formatNumber(_vm.marketSummaryData.volume, 0)
                : 0))])])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column col-4"},[_c('div',{staticClass:"d-flex flex-column border-bottom py-3"},[_c('label',[_vm._v("ราคาต่ำสุด")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.marketSummaryData ? _vm.marketSummaryData.low : 0)))])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column py-3"},[_c('label',[_vm._v("สถานะคงค้าง (สัญญา)")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.marketSummaryData ? _vm.formatNumber(_vm.marketSummaryData.oi, 0) : 0))])])])])],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column col-12 col-md-6 pt-4",staticStyle:{"min-height":"300px"}},[(!_vm.loadingChart && _vm.marketHomeChart[_vm.market.toLowerCase()])?_c('ContentLazy',{staticClass:"h-100 position-relative",scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ChartLoading',{attrs:{"position":"absolute"}})]},proxy:true}],null,false,3812418979)},[_vm._v(" "),_c('Chart2LineMarket',{class:{ 'market-summary-index-chart': _vm.market !== 'TFEX' },attrs:{"data-obj":_vm.marketHomeChart[_vm.market.toLowerCase()],"has-night-session":_vm.hasNightSession}})],1):_c('div',{staticClass:"d-flex align-items-center justify-content-center h-100 mb-5"},[_c('img',{staticClass:"chart-loading",attrs:{"src":"/images/loading/chart-loading.svg"}})]),_vm._v(" "),_c('ContentLazy',[(_vm.market !== 'TFEX')?_c('InputRangeMulti',{staticClass:"mb-3 input-range-multi-market-summary mt-auto",attrs:{"range-data":_vm.rangeData(),"is-custom-label":""},scopedSlots:_vm._u([{key:"progress-label(gainers)",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"\n                d-flex\n                align-items-center\n                title-font-family\n                fs-20px\n                position-relative\n              "},[_c('IconTriangle',{staticClass:"me-1",attrs:{"width":"8","height":"8"}}),_vm._v(" "),_c('span',{staticClass:"me-2"},[_vm._v(_vm._s(item.label))]),_vm._v(" "),_c('span',[_c('b',[_vm._v(_vm._s(_vm._f("numberWithCommas")(item.value)))])])],1)]}},{key:"progress-label(unchanges)",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"\n                d-flex\n                align-items-center\n                justify-content-center\n                title-font-family\n                fs-20px\n                position-relative\n              "},[_c('div',{staticClass:"d-flex normal pe-1"},[_c('IconTriangle',{staticClass:"normal-1",attrs:{"width":"7","height":"7"}}),_vm._v(" "),_c('IconTriangle',{staticClass:"normal-2",attrs:{"width":"7","height":"7"}})],1),_vm._v(" "),_c('span',{staticClass:"me-2"},[_vm._v(_vm._s(item.label))]),_vm._v(" "),_c('span',[_c('b',[_vm._v(_vm._s(_vm._f("numberWithCommas")(item.value)))])])])]}},{key:"progress-label(losers)",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"\n                d-flex\n                align-items-center\n                justify-content-end\n                title-font-family\n                fs-20px\n              "},[_c('IconTriangle',{staticClass:"me-1 down",attrs:{"width":"8","height":"8"}}),_vm._v(" "),_c('span',{staticClass:"me-2"},[_vm._v(_vm._s(item.label))]),_vm._v(" "),_c('span',[_c('b',[_vm._v(_vm._s(_vm._f("numberWithCommas")(item.value)))])])],1)]}}],null,false,3837096402)}):_vm._e()],1)],1)]),_vm._v(" "),(_vm.market === 'set')?_c('div',{staticClass:"\n      d-flex\n      flex-wrap\n      justify-content-md-between\n      flex-column-view-more\n      align-items-center\n      mb-2\n    "},[_c('div',{staticClass:"d-flex flex-column text-neutral-gray fs-12px col-md-7"},[_c('span',[_vm._v(" หมายเหตุ ")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('ul',{staticClass:"mb-0",staticStyle:{"padding-left":"1.2rem"}},[_c('li',[_vm._v("ข้อมูลเฉพาะหลักทรัพย์ที่มีการซื้อขายในรูปแบบ AOM")]),_vm._v(" "),_c('li',[_c('span',{staticClass:"drx-info"},[_vm._v(_vm._s(_vm.$t('drxNotation')))])])])])]),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center justify-content-end col-md-5 ms-auto pt-2 pt-md-0"},[_c('div',{staticClass:"border-right pe-md-3"},[_c('Button',{staticClass:"fs-20px text-middle-gray px-md-0 btn-view-stock lh-1 py-0",on:{"click":function($event){_vm.goMarketSETOverview(_vm.marketSummaryData.nameEN)
            _vm.eventTrackingReadMore()}}},[_vm._v("\n          รายชื่อหลักทรัพย์ "+_vm._s(_vm.marketSummaryData.nameEN)+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"ps-sm-3"},[_c('ButtonViewMore',{staticClass:"fs-20px lh-1 d-inline-flex align-items-center",attrs:{"title":'ดูเพิ่มเติม'},on:{"click":function($event){_vm.goMarketSummary(_vm.marketSummaryData.nameEN)
            _vm.eventTrackingReadMore()}}})],1)])]):_vm._e(),_vm._v(" "),(_vm.market === 'mai')?_c('div',{staticClass:"\n      d-flex\n      flex-wrap\n      justify-content-md-between\n      flex-column-view-more\n      align-items-center\n      mb-2\n    "},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center justify-content-end col-md-5 ms-auto pt-2 pt-md-0"},[_c('div',{staticClass:"border-right pe-md-3"},[_c('Button',{staticClass:"fs-20px text-middle-gray px-md-0 btn-view-stock lh-1 py-0",on:{"click":function($event){_vm.goMarketMaiOverview(_vm.marketSummaryData.nameEN)
            _vm.eventTrackingReadMore()}}},[_vm._v("\n          รายชื่อหลักทรัพย์ "+_vm._s(_vm.marketSummaryData.nameEN)+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"ps-sm-3"},[_c('ButtonViewMore',{staticClass:"fs-20px lh-1 d-inline-flex align-items-center",attrs:{"title":'ดูเพิ่มเติม'},on:{"click":function($event){_vm.goMarketSummary(_vm.marketSummaryData.nameEN)
            _vm.eventTrackingReadMore()}}})],1)])]):_vm._e(),_vm._v(" "),(_vm.market === 'TFEX')?_c('div',{staticClass:"row align-items-center mb-2"},[_c('div',{staticClass:"col-12 col-md-9"},[_c('span',{staticClass:"text-end fs-12px text-desc-aom"},[_vm._v("หมายเหตุ ราคาคิดเฉพาะการซื้อขายแบบ Auto-Matching\n        "),_c('span',{staticClass:"text-danger"},[_vm._v("ข้อมูลล่าช้า 15 นาที")]),_vm._v("\n        "+_vm._s(_vm._f("formatDateShortTimeLong")(_vm.marketSummaryData.marketTime)))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-3 text-end"},[_c('ButtonViewMore',{staticClass:"fs-20px",attrs:{"title":'ดูเพิ่มเติม'},on:{"click":function($event){_vm.gotoTfexOverview()
          _vm.eventTrackingReadMore()}}})],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column text-neutral-gray fs-12px col-md-7 me-auto"},[_c('span',[_vm._v(" หมายเหตุ ")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('ul',{staticClass:"mb-0",staticStyle:{"padding-left":"1.2rem"}},[_c('li',[_vm._v("ข้อมูลเฉพาะหลักทรัพย์ที่มีการซื้อขายในรูปแบบ AOM")])])])])}]

export { render, staticRenderFns }