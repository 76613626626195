//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uuid } from '@/utils/index'
export default {
  name: 'HomeListedCompany',
  data () {
    return {
      isDesktop: false,
      swiperOption: {
        showPagination: false,
        showNavigation: true,
        showNavigationOutSide: false,
        resizeObserver: true,
        centeredSlides: true,
        slidesPerView: 1
      },
      genUuid: this.uuid(),
      upcomingIPO: null,
      hasUpcomingIPO: true
    }
  },
  computed: {
    swiper () {
      return this.$refs.listedCompanyTabsCarousel.swiper
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize () {
      const screenSize = window.innerWidth
      if (screenSize >= 768) {
        this.isDesktop = true
      } else {
        this.isDesktop = false
      }
    },
    uuid
  }
}
