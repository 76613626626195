var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"statistical-data pt-2 pb-1"},[(_vm.dataStatistic)?_c('div',{staticClass:"row pb-3"},[_c('div',{staticClass:"col-12 market-date"},[_c('IconClock'),_vm._v(" "),_c('span',[_vm._v("ข้อมูล ณ วันที่ "+_vm._s(_vm._f("formatDateShort")(_vm.dataStatistic.date)))])],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-7 col-xl-7 table-type-summary"},[_c('TableCustomField',{staticClass:"table-type-summary shadow-none border-0 table-striped",attrs:{"fields":_vm.fields.statisticAndResult,"items":_vm.itemsStatistic,"table-class":"sr-only table-striped","sticky-header":"","no-border-collapse":""},scopedSlots:_vm._u([{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(item.type)+"\n        ")]}},{key:"cell(value)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(item.value)+"\n        ")]}}])})],1),_vm._v(" "),_c('div',{staticClass:"\n        col-12 col-sm-5 col-xl-5\n        table-change-summary table-striped\n        shadow-none\n      "},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',_vm._l((_vm.fields.marketChange),function(head,index){return _c('th',{key:index,class:[
                head.stickyColumn ? 'column-sticky' : '',
                head.thClass
              ],attrs:{"colspan":"2"}},[_vm._v("\n              "+_vm._s(head.label)+"\n              "),(head.stickyColumn)?_c('div',{staticClass:"aria-sort-highlight-dot"}):_vm._e()])}),0)]),_vm._v(" "),_c('tbody',[_vm._l((_vm.itemsMarketChange),function(row,index){return [_c('tr',{key:index},[_c('td',{staticClass:"fs-14px topic-stat"},[_vm._v("\n                "+_vm._s(row.type)+"\n              ")]),_vm._v(" "),_c('td',{staticClass:"title-font-family text-end fs-20px"},[_c('div',{class:_vm.integerStyle(row.value)},[_vm._v("\n                  "+_vm._s(_vm.formatNumberPercent(row.value))+"\n                ")])])])]})],2)])])]),_vm._v(" "),_c('div',{staticClass:"text-end fs-20px heading-font-family pt-3"},[_c('ButtonViewMore',{on:{"click":function($event){_vm.goMarketSummary(_vm.index)
        _vm.eventTrackingReadMore()}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }