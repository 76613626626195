//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
function guidelineOrCustomize (list) {
  return list && list.length > 0 ? '/guideline' : '/customize'
}
export default {
  name: 'HomeStockScreening',
  data () {
    return {
      swiperOption: {
        showPagination: false,
        showNavigation: true,
        // watchOverflow: true,
        pagination: {
          el: '.carousel-screening .swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.carousel-screening .swiper-btn-next',
          prevEl: '.carousel-screening .swiper-btn-prev'
        },
        showNavigationOutSide: true,
        freeMode: true,
        loop: false,
        slidesPerView: 1.8,
        slidesPerGroup: 1,
        spaceBetween: 10,
        slidesOffsetAfter: 14,
        breakpoints: {
          768: {
            freeMode: false,
            slidesPerView: 3,
            spaceBetween: 10,
            slidesOffsetAfter: 0
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 8,
            slidesOffsetAfter: 0
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 5,
            slidesOffsetAfter: 0
          }
        }
      },
      fundamentalList: [],
      growthList: [],
      dividendList: []
    }
  },
  // async fetch () {
  //   return await this.fetchData()
  // },
  computed: {
    ...mapGetters({
      stocks: 'search/getStocks'
    })
  },
  async mounted () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      const themes = ['fundamental', 'growth', 'dividend']
      let requestList = themes.map((theme) => {
        return this.$axios.$get(`/api/set/stock-screen/themes/${theme}/symbols`)
      })
      requestList = await Promise.all(requestList)

      this.fundamentalList = formatData(requestList[0])
      this.growthList = formatData(requestList[1])
      this.dividendList = formatData(requestList[2])

      function formatData (stockList) {
        try {
          return stockList.slice(0, 3)
        } catch (error) {
          return []
        }
      }
    },
    getStockSecurityType (symbol) {
      const stockInfo = this.stocks.find((data) => {
        return data.symbol === symbol
      })

      if (stockInfo) {
        return stockInfo.securityType
      }
    },
    gotoQuoteOverview (symbol) {
      const urlString = this.$quote.getQuoteUrl({
        symbol,
        securityType: this.getStockSecurityType(symbol)
      })
      // window.location.href = urlString
      return urlString
    },
    gotoStockScreening (theme) {
      let url = 'equities/stock-screening'
      if (theme) {
        url += `/${theme}`
        switch (theme) {
          case 'fundamental':
            url += guidelineOrCustomize(this.fundamentalList)
            break
          case 'growth':
            url += guidelineOrCustomize(this.growthList)
            break
          case 'dividend':
            url += guidelineOrCustomize(this.dividendList)
            break
          default:
            break
        }
      }
      return url
    },
    handleClickTracking (name, optionLang) {
      if (optionLang) {
        this.$personalized.clickElement({
          name,
          optionLang
        })
      } else {
        this.$personalized.clickElement({
          name
        })
      }
    }
  }
}
