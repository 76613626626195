import { render, staticRenderFns } from "./ListedCompany.vue?vue&type=template&id=2de7e495&"
import script from "./ListedCompany.vue?vue&type=script&lang=js&"
export * from "./ListedCompany.vue?vue&type=script&lang=js&"
import style0 from "./ListedCompany.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeListedCompanyFirstTradingDay: require('/app/components/Pages/Home/ListedCompany/firstTradingDay.vue').default,HomeListedCompanyUpcomingIPO: require('/app/components/Pages/Home/ListedCompany/upcomingIPO.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,HomeListedCompanyOpportunityDay: require('/app/components/Pages/Home/ListedCompany/opportunityDay.vue').default})
