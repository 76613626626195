import { render, staticRenderFns } from "./StockComparison.vue?vue&type=template&id=4e837760&"
import script from "./StockComparison.vue?vue&type=script&lang=js&"
export * from "./StockComparison.vue?vue&type=script&lang=js&"
import style0 from "./StockComparison.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/app/components/NavTabs/Tab.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,DropdownItem: require('/app/components/Dropdown/Item.vue').default,Dropdown: require('/app/components/Dropdown/Dropdown.vue').default,Placeholder: require('/app/components/Placeholder/Placeholder.vue').default,Chart2HomeStockCompare: require('/app/components/Chart2/HomeStockCompare.vue').default,IconClock: require('/app/components/Icon/Clock.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default,Message: require('/app/components/Message/index.vue').default})
