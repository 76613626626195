//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeGlobalInternationalCooperation',
  props: {
    showWidget: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    urlDW () {
      return this.localePath({ path: '/equities/dw/overview' })
    },
    urlDR () {
      return this.localePath({ path: '/equities/dr/overview' })
    },
    urlDRx () {
      return this.localePath({ path: '/equities/drx/overview' })
    }
  }
}
