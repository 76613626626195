//
//
//
//
//
//
//
//

export default {
  name: 'PlaceholderList'
}
