import { render, staticRenderFns } from "./Analyst.vue?vue&type=template&id=7b3e7d84&"
import script from "./Analyst.vue?vue&type=script&lang=js&"
export * from "./Analyst.vue?vue&type=script&lang=js&"
import style0 from "./Analyst.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeAnalystArticle: require('/app/components/Pages/Home/Analyst/AnalystArticle.vue').default,IconClock: require('/app/components/Icon/Clock.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default,HomeAnalystByIAA: require('/app/components/Pages/Home/Analyst/AnalystByIAA.vue').default})
