var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"id":_vm.tableId,"items":_vm.itemsPlaceHolder,"fields":_vm.fields,"responsive":_vm.responsive,"tbody-tr-class":_vm.tbodyTrClass,"selectable":false,"table-class":'table-placeholder table-custom-field '},scopedSlots:_vm._u([_vm._l((_vm.fields),function(fieldItem,index){return {key:("head(" + (fieldItem.key) + ")"),fn:function(ref){
var field = ref.field;
return [_vm._t(("head(" + (fieldItem.key) + ")"),function(){return [_vm._v("\n        "+_vm._s(field.label)+"\n      ")]},{"field":field,"index":index}),_vm._v(" "),_c('span',{key:index,staticClass:"aria-sort-highlight-dot"})]}}}),_vm._l((_vm.fields),function(field,indexField){return {key:("cell(" + (field.key) + ")"),fn:function(){return [_c('Placeholder',{key:indexField,staticClass:"rounded"})]},proxy:true}})],null,true)}),_vm._v(" "),_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],ref:_vm.tableId,attrs:{"id":_vm.tableId,"items":_vm.items,"fields":_vm.fields,"sort-by":_vm.items && _vm.items.length > 0 ? _vm.sortBy : '',"sort-desc":_vm.items && _vm.items.length > 0 ? _vm.sortDesc : '',"responsive":_vm.responsive,"per-page":_vm.perPage,"current-page":_vm.currentPage,"selectable":_vm.selectable,"select-mode":_vm.selectMode,"filter":_vm.filter,"filter-included-fields":_vm.filterOn,"tbody-tr-class":_vm.tbodyTrClass,"no-border-collapse":_vm.noBorderCollapse,"table-class":'table-custom-field table-custom-field--cnc ' +
        (_vm.tableHover ? 'table-hover-underline' : ''),"show-empty":_vm.showEmpty},on:{"sort-changed":_vm.sortChange},scopedSlots:_vm._u([{key:"thead-top",fn:function(ref){
        var fields = ref.fields;
        var columns = ref.columns;
return [_vm._t("thead-top",null,{"fields":fields,"columns":columns})]}},_vm._l((_vm.fields),function(fieldItem,index){return {key:("head(" + (fieldItem.key) + ")"),fn:function(ref){
        var field = ref.field;
return [_vm._t(("head(" + (fieldItem.key) + ")"),function(){return [_vm._v("\n        "+_vm._s(field.label)+"\n      ")]},{"field":field,"index":index}),_vm._v(" "),_c('span',{key:index,staticClass:"aria-sort-highlight-dot"})]}}}),_vm._l((_vm.fields),function(field){return {key:("cell(" + (field.key) + ")"),fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_vm._t(("cell(" + (field.key) + ")"),null,{"item":item,"index":index})]}}}),{key:"empty",fn:function(){return [_vm._t("empty",function(){return [_c('Message',{staticClass:"border-0",attrs:{"msg-title":_vm.msgTitle}})]})]},proxy:true}],null,true)}),_vm._v(" "),(_vm.showHintScroll === true)?_c('div',{staticClass:"hint-scroll-y d-flex align-items-center justify-content-center",class:{ hide: _vm.scrolled }},[_c('IconScrollTable')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }