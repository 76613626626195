import { render, staticRenderFns } from "./CardMarketIndex.vue?vue&type=template&id=918503e2&"
import script from "./CardMarketIndex.vue?vue&type=script&lang=js&"
export * from "./CardMarketIndex.vue?vue&type=script&lang=js&"
import style0 from "./CardMarketIndex.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconClock: require('/app/components/Icon/Clock.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default,IconTriangle: require('/app/components/Icon/Triangle.vue').default,Card: require('/app/components/Card/Card.vue').default})
