//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { handleImageError } from '@/helpers'
export default {
  name: 'MiscEquityCompanyLogo',
  props: {
    dataSrc: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleError (event) {
      const option = { src: this.dataSrc, ratio: '1x1' }
      const target = event.currentTarget
      handleImageError(target, option, () => {
        target.style.setProperty('padding', '0px', 'important')
      })
      target.classList.remove('border')
    }
  }
}
