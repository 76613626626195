import { render, staticRenderFns } from "./Cover.vue?vue&type=template&id=9f2f3d7c&scoped=true&"
import script from "./Cover.vue?vue&type=script&lang=js&"
export * from "./Cover.vue?vue&type=script&lang=js&"
import style0 from "./Cover.vue?vue&type=style&index=0&id=9f2f3d7c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f2f3d7c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/app/components/Picture/Picture.vue').default,Breadcrumb: require('/app/components/Breadcrumb/Breadcrumb.vue').default})
