//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { integerStyle, formatNumberInteger, formatNumberPercent, integerLevelStyle } from '@/helpers/index'
export default {
  name: 'HomeIndexSet',
  data () {
    return {
      indexTabContent: null,
      // isTablet: this.$device.isTablet,
      scrolled: false,
      showHintScroll: false,
      addEventAlready: false,
      indexTab: 'SET',
      indexParam: 'SET',
      marketSummaryData: {
        set: {},
        mai: {},
        tfex: {}
      },
      market: 'set',
      summaryTab: 'summaryMarket',
      summaryTabMai: 'summaryMarket',
      // marketHome22: [],
      marketHomeMai: []
    }
  },
  async fetch () {
    // if (process.server) {
    //   const payload = {
    //     market: 'SET'
    //   }
    //   await this.getTriIndex()
    //   await this.$store.dispatch('home/getMarketHome', payload).then(() => {
    //     this.setMarketHome22()
    //     this.initSetData()
    //   })
    // }
    const payload = {
      market: 'SET'
    }
    await this.getTriIndex()
    await this.$store.dispatch('home/getMarketHome', payload).then(() => {
      // this.setMarketHome22()
      this.initSetData()
    })
  },
  computed: {
    ...mapGetters({
      marketHome: 'home/getMarketHome',
      marketHomeTfex: 'home/getMarketHomeTfex',
      triIndex: 'home/getTriIndex'
    }),
    marketHome22 () {
      const indexList = {
        symbol: 'FTSESET',
        nameEN: 'FTSE SET Index Series',
        nameTH: 'FTSE SET Index Series',
        prior: null,
        high: null,
        low: null,
        last: null,
        change: null,
        percentChange: null,
        volume: null,
        value: null,
        querySymbol: 'FTSESET',
        marketStatus: null,
        marketDateTime: null,
        marketName: 'FTSESET',
        industryName: '',
        sectorName: '',
        level: 'INDEX'
      }
      let resData = [...this.marketHome, indexList]
      let triData = null
      if (this.triIndex && this.triIndex.data) {
        triData = this.triIndex.data?.find(data => data.symbol === 'SET')
      }
      if (triData) {
        const setTri = {
          symbol: triData.symbol + ' TRI',
          nameEN: triData.symbol + ' TRI',
          nameTH: triData.symbol + ' TRI',
          prior: null,
          high: null,
          low: null,
          last: triData.tri,
          change: triData.change,
          percentChange: triData.percentChange,
          volume: null,
          value: null,
          querySymbol: triData.symbol + ' TRI',
          marketStatus: null,
          marketDateTime: null,
          marketName: triData.symbol + ' TRI',
          industryName: '',
          sectorName: '',
          level: triData.level
        }
        resData = [...this.marketHome, setTri, indexList]
      }
      return resData
    }
    // marketHomeMai () {
    //   let resData = [...this.marketHome]
    //   // const triData = this.triIndex.data?.find(data => data.symbol === 'mai')
    //   let triData = null
    //   if (this.triIndex && this.triIndex.data) {
    //     triData = this.triIndex.data?.find(data => data.symbol === 'mai')
    //   }
    //   if (triData) {
    //     const setTri = {
    //       symbol: triData.symbol + ' TRI',
    //       nameEN: triData.symbol + ' TRI',
    //       nameTH: triData.symbol + ' TRI',
    //       prior: null,
    //       high: null,
    //       low: null,
    //       last: triData.tri,
    //       change: triData.change,
    //       percentChange: triData.percentChange,
    //       volume: null,
    //       value: null,
    //       querySymbol: triData.symbol + ' TRI',
    //       marketStatus: null,
    //       marketDateTime: null,
    //       marketName: triData.symbol + ' TRI',
    //       industryName: '',
    //       sectorName: '',
    //       level: triData.level
    //     }
    //     resData = [...this.marketHome, setTri]
    //   }
    //   return resData
    // }
  },
  // watch: {
  //   marketHomeTfex: {
  //     handler (val) {
  //       console.log('watch marketHomeTfex')
  //       if (val) {
  //         this.changeMarketTab(val[0], 'TFEX')
  //       }
  //     }
  //   }
  //   marketHome () {
  //     console.log('watch marketHome')
  //     if (this.indexTab === 'SET') {
  //       this.marketSummaryData.set = this.marketHome22.find(
  //         item => item.symbol === 'SET'
  //       )
  //     }
  //   }
  // },
  updated () {
    this.$nextTick(() => {
      // this.checkDeviceSize()
      this.registerEvent()
      this.handleHint()
      this.initResizeTabIndex()
    })
  },
  // created () {
  //   this.checkDeviceSize()
  // },
  mounted () {
    this.initChartData()
  },
  beforeDestroy () {
    this.removeEvent()
    if (
      this.indexTabContent &&
      this.$refs.mainIndexTabContent &&
      this.$refs.mainIndexTabContent.$el
    ) {
      this.indexTabContent.unobserve(this.$refs.mainIndexTabContent.$el)
    }
  },
  methods: {
    integerStyle,
    formatNumberInteger,
    formatNumberPercent,
    integerLevelStyle,
    ...mapActions('home', ['getTriIndex']),
    initChartData () {
      const payload = {
        market: 'SET',
        rankingType: 'mostActiveVolume',
        securityType: 'S',
        count: 5,
        tab: 'set'
      }
      // this.getTriIndex()
      // this.$store.dispatch('home/getMarketHome', payload)
      this.$store.dispatch('home/getMarketHomeChart', payload)
      this.$store.dispatch('home/getMarketInfoUnderHomeChart', payload)
      if (this.marketHome22.length > 0) {
        // this.marketSummaryData.set = this.marketHome22.find(item => item.symbol === 'SET')
        this.$store.dispatch('home/setLoadingChart', false)
      }
    },
    initSetData () {
      this.marketSummaryData.set = this.marketHome22.find(
        item => item.symbol === 'SET'
      )
    },
    initResizeTabIndex () {
      // if (this.$refs.mainIndexTabContent) {
      //   console.log('this.$refs.mainIndexTabContent', this.$refs.mainIndexTabContent)
      // }
      if (
        this.indexTabContent === null &&
        this.$refs.mainIndexTabContent &&
        this.$refs.mainIndexTabContent.$el
      ) {
        this.indexTabContent = new ResizeObserver(this.onResizeTabIndex)
        this.indexTabContent.observe(this.$refs.mainIndexTabContent.$el)
      }
    },
    onResizeTabIndex () {
      if (
        this.indexTabContent &&
        this.$refs.mainIndexTabContent &&
        this.$refs.mainIndexTabContent.$el
      ) {
        const ele = this.$refs.mainIndexTabContent.$el
        const { deviceSizes } = require('~/config/core')
        const screenWidth = window.innerWidth
        ele.removeAttribute('style')
        this.$nextTick(() => {
          if (screenWidth >= deviceSizes.lg) {
            ele.style.setProperty(
              '--heightTabIndexHome',
              `${ele.offsetHeight}px`
            )
          } else {
            ele.removeAttribute('style')
          }
        })
      }
    },
    displayName (objItem) {
      const lang = this.$i18n.locale
      const key = `name${lang.toUpperCase()}`
      const item = objItem || {}
      return item[key] || ''
    },
    registerEvent () {
      const element = this.$refs.indexSetStockWrapper
      if (element && !this.addEventAlready) {
        element.addEventListener('scroll', this.handleScroll)
        this.addEventAlready = true
      }
      // window.addEventListener('resize', this.checkDeviceSize)
    },
    removeEvent () {
      const element = this.$refs.indexSetStockWrapper
      if (element) {
        element.removeEventListener('scroll', this.handleScroll)
      }
      // window.removeEventListener('resize', this.checkDeviceSize)
    },
    checkDeviceSize () {
      const wInnerWidth = window.innerWidth
      const { deviceSizes } = require('~/config/core')
      if (wInnerWidth <= deviceSizes.lg) {
        this.isTablet = true
      } else {
        this.isTablet = false
      }
    },
    handleScroll () {
      if (this.scrolled) {
        return
      }
      this.scrolled = true
    },
    handleHint () {
      if (!this.$refs.indexSetStockTabs) {
        return
      }
      const indexSetStockWrapper = this.$refs.indexSetStockWrapper
      const indexSetStockTabs = this.$refs.indexSetStockTabs.$el
      const wrapperH = indexSetStockWrapper.clientHeight
      const innerH = indexSetStockTabs.clientHeight
      if (this.scrolled) {
        setTimeout(() => {
          this.showHintScroll = false
        }, 200)
      } else {
        this.showHintScroll = innerH > wrapperH && this.scrolled === false
      }
    },
    async changeIndexTab (market) {
      this.$store.dispatch('home/setLoadingChart', true)
      this.indexTab = market
      this.market = market
      if (market === 'TFEX') {
        this.marketSummaryData.set = {}
        this.marketSummaryData.mai = {}
        this.marketSummaryData.tfex = {}
        await this.$store
          .dispatch('home/getMarketHomeTfexInstrument')
          .then(() => {
            const firstSeries = this.marketHomeTfex
            if (firstSeries && firstSeries.length > 0) {
              this.changeMarketTab(firstSeries[0], 'TFEX')
            }
          })
      } else {
        const payload = { market, tab: market }
        await this.$store.dispatch('home/getMarketHome', payload)

        if (market === 'mai') {
          this.setMarketHomeMai()
        }
        await this.$store.dispatch('home/getMarketInfoUnderHomeChart', payload)
        const marketSummaryData = this.marketHome.find(
          item => item.symbol === this.market
        )
        this.changeMarketTab(marketSummaryData, market.toLowerCase())
      }
      this.onResizeTabIndex()
    },
    async changeMarketTab (item, cate) {
      // const marketSummaryData = this.marketHome.find(item => item.symbol.toLowerCase() === cate.toLowerCase())
      this.indexParam = this.displayName(item) // item.nameEN
      if (item) {
        this.$store.dispatch('home/setLoadingChart', true)
        if (this.market === cate) {
          this.indexTab = item.symbol
        }
        this.marketSummaryData[cate.toLowerCase()] = item
        const payload = { market: item.symbol, tab: cate.toLowerCase() }
        if (cate === 'TFEX') {
          await this.$store.dispatch('home/getTfexHomeChart', payload)
        } else if (item.querySymbol !== 'FTSESET') {
          await this.$store.dispatch('home/getMarketHomeChart', payload)
        }
        if (this.indexTab !== 'SET') {
          this.summaryTab = 'summaryMarket'
        }
        if (this.indexTab !== 'mai') {
          this.summaryTabMai = 'summaryMarket'
        }
      }
    },
    changeSummaryMarketTab (summaryTab) {
      this.summaryTab = summaryTab
      if (
        this.market.toUpperCase() === 'SET' &&
        this.summaryTab !== 'summaryMarket' &&
        this.indexTab !== 'SET'
      ) {
        this.changeIndexTab('SET')
      }
    },
    changeSummaryMarketTabMai (summaryTab) {
      this.changeIndexTab('mai')
      this.summaryTabMai = summaryTab
    },
    initTab () {
      this.$nextTick(() => {
        setTimeout(() => {
          this.activeTabGlobalByHash()
        }, 200)
      })
    },
    activeTabGlobalByHash () {
      const hash = window.location.hash
      if (hash) {
        const hashAfterReplace = hash?.replace('#', '')
        const hashArr = hashAfterReplace?.split('-')
        const elementTabGlobal = document.getElementById(hashArr[0])
        elementTabGlobal?.click()
      }
    },
    eventTrackingTab (menu) {
      this.$personalized.clickElement({
        name: 'home4',
        optionLang: {
          menu
        }
      })
    },
    setMarketHome22 () {
      const indexList = {
        symbol: 'FTSESET',
        nameEN: 'FTSE SET Index Series',
        nameTH: 'FTSE SET Index Series',
        prior: null,
        high: null,
        low: null,
        last: null,
        change: null,
        percentChange: null,
        volume: null,
        value: null,
        querySymbol: 'FTSESET',
        marketStatus: null,
        marketDateTime: null,
        marketName: 'FTSESET',
        industryName: '',
        sectorName: '',
        level: 'INDEX'
      }
      let resData = [...this.marketHome, indexList]
      let triData = null
      if (this.triIndex && this.triIndex.data) {
        triData = this.triIndex.data?.find(data => data.symbol === 'SET')
      }
      if (triData) {
        const setTri = {
          symbol: triData.symbol + ' TRI',
          nameEN: triData.symbol + ' TRI',
          nameTH: triData.symbol + ' TRI',
          prior: null,
          high: null,
          low: null,
          last: triData.tri,
          change: triData.change,
          percentChange: triData.percentChange,
          volume: null,
          value: null,
          querySymbol: triData.symbol + ' TRI',
          marketStatus: null,
          marketDateTime: null,
          marketName: triData.symbol + ' TRI',
          industryName: '',
          sectorName: '',
          level: triData.level
        }
        resData = [...this.marketHome, setTri, indexList]
      }
      this.marketHome22 = resData
    },
    setMarketHomeMai () {
      let resData = [...this.marketHome]
      // const triData = this.triIndex.data?.find(data => data.symbol === 'mai')
      let triData = null
      if (this.triIndex && this.triIndex.data) {
        triData = this.triIndex.data?.find(data => data.symbol === 'mai')
      }
      if (triData) {
        const setTri = {
          symbol: triData.symbol + ' TRI',
          nameEN: triData.symbol + ' TRI',
          nameTH: triData.symbol + ' TRI',
          prior: null,
          high: null,
          low: null,
          last: triData.tri,
          change: triData.change,
          percentChange: triData.percentChange,
          volume: null,
          value: null,
          querySymbol: triData.symbol + ' TRI',
          marketStatus: null,
          marketDateTime: null,
          marketName: triData.symbol + ' TRI',
          industryName: '',
          sectorName: '',
          level: triData.level
        }
        resData = [...this.marketHome, setTri]
      }
      this.marketHomeMai = resData
    }
  }
}
