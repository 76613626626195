//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getIdFromUrl } from 'vue-youtube'
// import CompanyContact from '@/components/Misc/Equity/IPO/UpcomingIpo/CompanyContact.vue'
// import DatePeriods from '~/components/Misc/Equity/IPO/UpcomingIpo/DatePeriods.vue'
export default {
  name: 'HomeListedCompanyUpcomingIPO',
  components: {
    // CompanyContact
    // DatePeriods
  },
  filters: {
    industryFormat (value) {
      if (!value) {
        return '-'
      } else if (value.sectorName) {
        return `${value.industryName} / ${value.sectorName}`
      } else {
        return value.industryName
      }
    }
  },
  data () {
    return {
      loading: true,
      upcomingIpoData: [],
      items: [],
      currentType: 'effective',
      viewMoreUrl: '',
      isDesktop: true,
      isMobile: false,
      swiperOption: {
        showPagination: false,
        showNavigation: true,
        showNavigationOutSide: true,
        resizeObserver: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        pagination: {
          el: '.upcoming .swiper-pagination',
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"></span>'
          }
        },
        navigation: {
          nextEl: '.upcoming .swiper-btn-next',
          prevEl: '.upcoming .swiper-btn-prev'
        },
        loop: false,
        spaceBetween: 0,
        breakpoints: {
          992: {
            slidesPerView: 2,
            slidesPerGroup: 1
          }
        }
      }
    }
  },
  computed: {
    swiper () {
      let swp = null
      if (this.$refs.upcomingCarousel) {
        swp = this.$refs.upcomingCarousel.swiper
      }
      return swp
    }
  },
  watch: {
    upcomingIpoData () {
      const res_ = (this.upcomingIpoData ?? []).map((x, index) => {
        return {
          ...x,
          issuedSize:
            x.issuedSize === null ? x.issuedSize : x.issuedSize / 1000000,
          marketCap: x.marketCap === null ? x.marketCap : x.marketCap / 1000000,
          videoId:
            x.market === 'SET' || x.market === 'mai'
              ? getIdFromUrl(x.vdoPresentationUrl)
              : null
        }
      })
      if (res_.length > 10) {
        res_.length = 10
      }
      this.items = res_
    }
  },
  async mounted () {
    await this.fetchDataIpo()
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    this.$nextTick(() => {
      this.onSwiperInit()
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    async getDataIPO (status) {
      const option = {
        params: {
          lang: this.$i18n.locale,
          type: null,
          status,
          limit: 0
        }
      }
      const response = await this.$axios.get('/api/set/ipo/upcoming', option)
        .then(res => res)
        .catch(err => err)
      if (response?.status === 200) {
        const { data: resData } = response ?? {}
        return resData?.data ?? []
      }
      return []
    },
    async fetchDataIpo () {
      this.viewMoreUrl = this.getViewMoreUrl('effective')
      let items = await this.getDataIPO('Effective')
      if (!items.length) {
        this.viewMoreUrl = this.getViewMoreUrl('approved')
        this.currentType = 'approved'
        items = await this.getDataIPO('Approved')
      }
      this.upcomingIpoData = items
      this.loading = false
    },
    onSwiperInit () {
      if (this.swiper) {
        this.swiper.on('breakpoint', (e) => {
          this.swiper.update()
        })
      }
    },
    getViewMoreUrl (type) {
      const url = this.localePath({
        name: 'equities-ipo-corner-upcoming-ipo-all',
        params: { pathMatch: type === 'effective' ? 'effective-filing' : 'approved-application' }
      })
      return url
    },
    onResize () {
      const screenSize = window.innerWidth
      if (screenSize > 1024) {
        this.isDesktop = true
        this.isMobile = false
      } else if (screenSize < 768) {
        this.isDesktop = false
        this.isMobile = true
      } else {
        this.isDesktop = true
      }
    },
    modalVideoOpen (id) {
      this.$refs.modalVideo.modalOpen({
        videoId: id
      })
    },
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    },
    handleClickCompanyContactTracking (item, buttonText) {
      this.$personalized.clickElement({
        name: 'home55',
        optionLang: {
          contentTitle: 'Upcoming IPO',
          symbol: item.symbol,
          buttonText
        }
      })
    }
  }
}
