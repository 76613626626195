//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const componentEconomic = new Map()
const defaultComponent = () => import('./Gold.vue')
componentEconomic.set('gold', { component: () => import('./Gold.vue') })
componentEconomic.set('oil', { component: () => import('./Oil.vue') })
componentEconomic.set('exchange', { component: () => import('./Exchange.vue') })
componentEconomic.set('interest', { component: () => import('./Interest.vue') })
componentEconomic.set('risingPrice', { component: () => import('./RisingPrice.vue') })
componentEconomic.set('metal', { component: () => import('./Metal.vue') })
export default {
  name: 'Economic',
  data () {
    return {
      swiperOptionEconomic: {
        grabCursor: true,
        watchOverflow: true,
        slidesPerView: 'auto',
        resizeObserver: true,
        navigation: {
          nextEl: '.economic-wrapper .swiper-btn-next',
          prevEl: '.economic-wrapper .swiper-btn-prev'
        }
      },
      economicTabs: [
        {
          text: 'ราคาทองคำ',
          value: 'gold'
        },
        {
          text: 'ราคาน้ำมัน ',
          value: 'oil'
        },
        {
          text: 'อัตราแลกเปลี่ยน',
          value: 'exchange'
        },
        {
          text: 'อัตราดอกเบี้ย',
          value: 'interest'
        },
        {
          text: 'เงินเฟ้อ',
          value: 'risingPrice'
        },
        {
          text: 'โลหะ',
          value: 'metal'
        }
      ],
      selectEconomic: 'gold'
    }
  },
  computed: {
    componentEconomic () {
      return componentEconomic.get(this.selectEconomic)?.component ?? defaultComponent
    }
  },
  methods: {
    initSwiper (swiper) {
      // bug mobile fires resize event on scroll
      // let oldWidth = window.innerWidth
      // swiper.on('resize', () => {
      //   const newWidth = window.innerWidth
      //   if (oldWidth !== newWidth) {
      //     oldWidth = window.innerWidth
      //     this.handleSwiperResize(swiper)
      //   }
      // })
      swiper.update()
      swiper.updateSize()
    },
    onSelectEconomic (value) {
      this.selectEconomic = value
    }
  }
}
