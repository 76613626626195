var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"investors-type-summary pt-2 pb-1"},[(_vm.dataSummary)?_c('div',{staticClass:"row pb-3"},[_c('div',{staticClass:"col-12 col-lg-6 market-date"},[_c('IconClock'),_vm._v(" "),_c('span',[_vm._v("ข้อมูล ณ วันที่ "+_vm._s(_vm._f("formatDateShort")(_vm.dataSummary.asOfDate)))])],1),_vm._v(" "),_c('div',{staticClass:"\n        col-12 col-lg-6\n        text-start\n        align-items-end\n        text-md-end\n        d-flex\n        heading1-font-family\n        justify-content-end\n        fs-20px\n        text-secondary\n      "},[_vm._v("\n      มูลค่าการซื้อขายรวม\n      "+_vm._s(_vm._f("convertToMillionFormat")(_vm.dataSummary.totalValue))+" ล้านบาท\n    ")])]):_vm._e(),_vm._v(" "),_c('TableCustomField',{staticClass:"table-type-summary shadow-none border-0",attrs:{"fields":_vm.fields,"items":_vm.items,"table-class":"sr-only","sticky-header":"","no-border-collapse":""},on:{"dragChanged":function($event){_vm.fields = $event}},scopedSlots:_vm._u([{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.lable)+"\n    ")]}},{key:"cell(buyValue)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm._f("convertToMillionFormat")(item.buyValue))+"\n    ")]}},{key:"cell(sellValue)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm._f("convertToMillionFormat")(item.sellValue))+"\n    ")]}},{key:"cell(netValue)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end fs-20 title-font-family",class:_vm.numberColor(item.netValue)},[_vm._v("\n        "+_vm._s(_vm._f("formatNumberInteger")((item.netValue / 1000000)))+"\n      ")])]}}])}),_vm._v(" "),_c('div',{staticClass:"unit fs-12px mt-2"},[_vm._v("\n    หน่วย : ล้านบาท\n  ")]),_vm._v(" "),_c('div',{staticClass:"text-end fs-20px heading-font-family"},[_c('ButtonViewMore',{attrs:{"title":_vm.$t('buttons.viewMore')},on:{"click":function($event){_vm.goMarketSummary()
        _vm.eventTrackingReadMore()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }