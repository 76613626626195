//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import dayjs from 'dayjs'
import { openTab } from '~/helpers'
export default {
  name: 'AnalystArticle',
  data () {
    return {
      loading: true,
      article: [],
      params: {
        cate: '',
        fromDate: dayjs().add(-7, 'day').format('DD/MM/YYYY'),
        toDate: dayjs().format('DD/MM/YYYY'),
        pageIndex: 0,
        pageSize: 8
      },
      swiperOptionArticle: {
        // showPagination: true,
        navigation: false,
        showNavigation: false,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
        grabCursor: true,
        resizeObserver: true,
        direction: 'horizontal',
        pagination: {
          el: '.analyst-article  .swiper-pagination-analyst',
          // dynamicBullets: true,
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"><div class="circle"></div></span>'
          }
        },
        breakpoints: {
          768: {
            spaceBetween: 0,
            slidesPerView: 2,
            slidesPerGroup: 2,
            direction: 'vertical',
            allowTouchMove: false
          }
        }
      }
    }
  },
  async fetch () {
    await this.searchAnalyst(this.params)
    this.article = _.chunk(this.analystResult, 4) || []
    this.loading = false
    // this.article = []
  },
  computed: {
    ...mapState('research/analyst-research', {
      analystResult: state => state.analystResult ? state.analystResult.resultInfoList.items : []
    })
  },
  mounted () {
  },
  methods: {
    ...mapActions('research/analyst-research', ['searchAnalyst']),
    viewMoreArticle () {
      this.handleClickTracking('home63', { buttonText: 'ดูเพิ่มเติม' })
      openTab('research/analyst-research/main')
    },
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
  }
}
