//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ButtonLinkOut',
  props: {
    href: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_blank'
    },
    text: {
      type: String,
      default: ''
    }
  }
}
