//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 10)
export default {
  name: 'TableCustomField',
  props: {
    responsive: {
      type: Boolean,
      default: true
    },
    sortBy: {
      type: String,
      default: ''
    },
    filter: {
      type: String,
      default: null
    },
    filterOn: {
      type: Array,
      default: () => []
    },
    sortDesc: {
      type: Boolean,
      default: false
    },
    noBorderCollapse: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    selectMode: {
      type: String,
      default: 'multi'
    },
    tableHover: {
      type: Boolean,
      default: true
    },
    tbodyTrClass: {
      type: [Array, Object, String, Function],
      default: () => {}
    },
    showEmpty: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    msgTitle: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      tableId: '',
      scrolled: false,
      showHintScroll: false,
      addEventAlready: false,
      itemsPlaceHolder: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
    }
  },

  mounted () {
    this.tableId = `table-${nanoid()}`

    // this.$nextTick(() => {
    //   this.registerEvent()
    //   this.handleHint()
    // })
  },

  // beforeDestroy () {
  //   this.removeEvent()
  // },

  methods: {
    registerEvent () {
      const element = document.getElementById(this.tableId)
      // console.log('🚀 ~ element', element)
      if (element && !this.addEventAlready) {
        element.addEventListener('scroll', this.handleScroll)
        this.addEventAlready = true
      }
    },

    removeEvent () {
      const element = document.getElementById(this.tableId)
      if (element) {
        element.removeEventListener('scroll', this.handleScroll)
      }
    },

    handleScroll () {
      if (this.scrolled) {
        return
      }
      this.scrolled = true
    },

    handleHint () {
      if (!this.$refs.indexSetStockTabs) {
        return
      }
      const indexSetStockWrapper = this.$refs.indexSetStockWrapper
      const indexSetStockTabs = this.$refs.indexSetStockTabs.$el
      const wrapperH = indexSetStockWrapper.clientHeight
      const innerH = indexSetStockTabs.clientHeight
      if (this.scrolled) {
        setTimeout(() => {
          this.showHintScroll = false
        }, 200)
      } else {
        this.showHintScroll = innerH > wrapperH && this.scrolled === false
      }
    },

    sortChange (sortItem) {
      this.$nextTick(() => {
        this.resetSortStyle()
        if (sortItem && sortItem.sortBy) {
          const sortElement = document.querySelector(
            `#${this.tableId} [aria-sort="ascending"],[aria-sort="descending"]`
          )
          if (sortElement) {
            const colindex = sortElement.getAttribute('aria-colindex')
            const tdColindexElement = document.querySelectorAll(
              `#${this.tableId} td[aria-colindex="${colindex}"]`
            )
            if (tdColindexElement) {
              tdColindexElement.forEach((ele) => {
                ele.classList.add('sortby-highlight')
              })
            }
          }
        }
        this.$emit('sort-changed', sortItem)
      })
    },

    resetSortStyle () {
      const sortHighlightElement = document.querySelectorAll(
        `#${this.tableId} .sortby-highlight`
      )
      if (sortHighlightElement) {
        sortHighlightElement.forEach((ele) => {
          ele.classList.remove('sortby-highlight')
        })
      }
    }
  }
}
