//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { numberColor } from '@/helpers/index'
export default {
  name: 'InvestorsTypeSummary',
  props: {
    quote: {
      type: String,
      default: 'SET'
    },
    indexTab: {
      type: String,
      default: 'SET'
    }
  },
  data () {
    return {
      dataSummary: null,
      fields: [
        {
          label: 'นักลงทุน',
          key: 'type',
          thClass: 'fw-normal fs-12px col-3',
          tdClass: ''
        },
        {
          label: 'ซื้อ',
          key: 'buyValue',
          thClass: 'fw-normal fs-12px text-end col-3',
          tdClass: 'text-end'
        },
        {
          label: 'ขาย',
          key: 'sellValue',
          thClass: 'fw-normal fs-12px text-end col-3',
          tdClass: 'text-end'
        },
        {
          label: 'สุทธิ',
          key: 'netValue',
          thClass: 'fw-normal fs-12px text-end col-3',
          tdClass: 'text-end'
        }
      ],
      itemsLable: [
        {
          lable: 'นักลงทุนสถาบัน'
        },
        {
          lable: 'บัญชีบริษัทหลักทรัพย์'
        },
        {
          lable: 'นักลงทุนต่างประเทศ'
        },
        {
          lable: 'นักลงทุนในประเทศ'
        }
      ]
    }
  },
  // async fetch () {
  //   await this.$axios.get(`api/set/market/${this.quote}/investor-type`).then((res) => {
  //     this.dataSummary = res.data
  //   }).catch(err => err)
  // },
  computed: {
    items () {
      let result = []
      if (this.dataSummary && this.dataSummary.investors.length) {
        const investors = this.dataSummary.investors
        result = investors.map((item, i) => {
          return { ...item, ...this.itemsLable[i] }
        })
      }
      return result
    }
  },
  async mounted () {
    await this.$axios
      .get(`api/set/market/${this.quote}/investor-type`)
      .then((res) => {
        this.dataSummary = res.data
      })
      .catch(err => err)
  },
  methods: {
    numberColor,
    goMarketSummary () {
      const route = this.$router.resolve({
        path: 'equities/market-data/historical-report/investor-type',
        query: {
          market: this.quote
        }
      })
      window.open(route.href)
    },

    eventTrackingReadMore () {
      this.$personalized.clickElement({
        name: 'home6',
        optionLang: {
          market: this.indexTab
        }
      })
    }
  }
}
