//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MiscEquityPicture',
  props: {
    sources: {
      type: Array,
      default: null
    },
    lazyImage: {
      type: Boolean,
      default: () => true
    },
    ratio: {
      type: String,
      default: '16x9'
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  computed: {
    placeholderImage () {
      if (this.placeholder) {
        return this.placeholder
      }
      if (this.ratio === '4x3') {
        return '/images/placeholder-4x3.jpg'
      }
      return '/images/placeholder-16x9.jpg'
    },
    images () {
      if (this.sources) {
        const image = this.sources
        let url = image.filter((img) => {
          return img.size === 'thumbnail'
        })
        if (url.length === 0) {
          url = image.filter((img) => {
            return img.size === 'default'
          })
        }
        return `${url[0]?.url}?v=${new Date().getTime()}` ?? ''
      }
      return ''
    }
  },
  methods: {
    replaceByDefault (e) {
      e.target.src = this.placeholderImage
      e.target.classList.add('error')
    },
    handleLoad (e) {
      const img = e.target
      if (!img) { return }
      const { height, width } = img
      const isVertical = height > width
      const isSquare = height === width
      if (isVertical === true) {
        img.classList.add('vertical')
      } else if (isSquare === true) {
        img.classList.add('square')
      } else {
        img.classList.add('image-horizontal')
      }
      // if (isSquare) {
      //   img.classList.add('square')
      // }
    }
  }
}
