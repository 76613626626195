//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModalVideo',
  data () {
    return {
      videoId: '',
      playerVars: {
        autoplay: 1,
        playsinline: 1,
        rel: 0
      },
      playerReady: false
    }
  },
  computed: {
    player () {
      return this.$refs.youtubePlayer.player
    }
  },
  mounted () {
  },
  methods: {
    modalOpen ({ videoId }) {
      this.videoId = videoId
      this.$refs.modalYoutubeVideo.show()
      this.playVideo()
    },
    playVideo () {
      this.player.playVideo()
      // this.exitFullscreen()
    },
    exitFullscreen () {
      const myPlayer = document.getElementsByClassName('html5-video-container')
      myPlayer.webkitExitFullscreen()
    },
    setMediaPlay (event) {
      this.playVideo()
    },
    onClose () {
      // console.log(this.player)
      this.player.pauseVideo()
    },
    handleOnShown () {
      this.playerReady = true
    }
  }
}
