//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeCoverImage',
  props: {
    cacheKey: {
      type: String,
      default: null
    }
  },
  serverCacheKey: props => props.cacheKey
}
