import * as amCore from '@amcharts/amcharts4/core'
import * as amCharts from '@amcharts/amcharts4/charts'
import * as amRangeSelector from '@amcharts/amcharts4/plugins/rangeSelector'
import amThemesAnimated from '@amcharts/amcharts4/themes/animated'
import amLangTH from '@amcharts/amcharts4/lang/th_TH'

// chart license
// const CHART_LICENSE_V5 = 'AM5C311284907'
const CHART_LICENSE_V4 = 'CH311284907'
amCore.addLicense(CHART_LICENSE_V4)

export { amCore, amCharts, amRangeSelector, amThemesAnimated, amLangTH }
