//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { openTab } from '~/helpers'
export default {
  name: 'NewsAndEconomic',
  data () {
    return {
      isMobile: this.$device.isMobile,
      show: true,
      currentTab: { tab: 'news', cate: '' },
      iaaMarket: 'SET',
      consensusMarketTimeVal: null
    }
  },
  mounted () {
    this.checkDevice()
    this.consensusMarketTime()
  },
  // beforeDestroy () {
  //   this.removeEvents()
  // },
  methods: {
    // registerEvents () {
    //   document.addEventListener('resize', this.handleResize)
    // },
    // removeEvents () {
    //   document.removeEventListener('resize', this.handleResize)
    // },
    handleSwiperResize (swiper) {
      this.checkDevice()
      this.show = false
      this.$nextTick(() => {
        this.show = true
        swiper.destroy()
        this.$nextTick(() => {
          // re-render end
          swiper.update()
        })
      })
    },
    checkDevice () {
      const { deviceSizes } = require('~/config/core')
      if (window.innerWidth >= deviceSizes.xl) {
        this.isMobile = false
      } else {
        this.isMobile = true
      }
    },
    changeTab (value) {
      this.currentTab = value
    },
    newTabViewMore () {
      if (this.currentTab.tab !== 'economic') {
        const section = this.currentTab.tab === 'news' ? 'ข่าวสาร' : 'บทความ'
        openTab(`news-and-articles/${this.currentTab.tab}/main`)
        this.handleClickTracking('home42', { section, buttonText: this.$t('buttons.viewMore') })
      } else {
        let category = ''
        switch (this.currentTab.cate) {
          case 'gold':
            category = 'ราคาทองคำ'
            openTab('economic-data/gold-prices')
            break
          case 'oil':
            category = 'ราคาน้ำมัน'
            openTab('economic-data/oil-prices')
            break
          case 'exchange':
            category = 'อัตราแลกเปลี่ยน'
            openTab('economic-data/foreign-exchange-rate')
            break
          case 'interest':
            category = 'อัตราดอกเบี้ย'
            openTab('economic-data/interest-rate/deposit')
            break
          case 'risingPrice':
            category = 'เงินเฟ้อ'
            openTab('economic-data/inflation')
            break
          case 'metal':
            category = 'โลหะ'
            openTab('economic-data/metal-prices')
            break
          default:
            category = 'ราคาทองคำ'
            openTab('economic-data/gold-prices')
            break
        }
        this.handleClickTracking('home43', { category, buttonText: this.$t('buttons.viewMore') })
      }
    },
    newTabIaaViewMore () {
      this.handleClickTracking('home51', { market: 'SET' })
      openTab('research/iaa-consensus/main')
    },
    newTabIaaTfexViewMore () {
      this.handleClickTracking('home51', { market: 'TFEX' })
      openTab('research/iaa-tfex-consensus/main')
    },
    consensusMarketTime (val) {
      this.consensusMarketTimeVal = val
    },
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
  }
}
