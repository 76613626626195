//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { formatDateShort } from '@/helpers/dateTime'
export default {
  name: 'AnalystByIAA',
  data () {
    return {
      loading: true,
      tabs: [
        {
          text: 'SET',
          controls: 'iaa-consensus-set',
          value: 'SET'
        },
        {
          text: 'TFEX',
          controls: 'iaa-consensus-tfex',
          value: 'TFEX'
        }
      ],
      fields: [
        {
          label: 'ชื่อย่อหลักทรัพย์',
          key: 'product',
          thClass: 'text-start fw-100 iaa-header mw-120 mw-sm-auto text-nowrap',
          tdClass: 'w-20 py-0 mw-120 mw-sm-auto'
        },
        {
          label: 'Target Price (Median)',
          key: 'target',
          thClass: 'text-end text-nowrap fw-200 iaa-header',
          tdClass: 'text-end align-middle py-0'
        },
        {
          label: 'Analyst Recommendation',
          key: 'recommend',
          thStyle: { minWidth: '235px' },
          thClass: 'fw-400 text-nowrap iaa-header',
          tdClass: 'py-0'
        }
      ],
      fieldsTfex: [
        {
          label: 'ชื่อย่อสัญญา',
          key: 'product',
          thClass: 'text-start text-nowrap fw-100 iaa-header w-20 ',
          tdClass: 'w-20 py-1'
        },
        {
          label: 'ราคาฟิวเจอร์ส (แนวรับ-แนวต้าน)',
          key: 'target',
          thClass: 'text-end text-nowrap fw-200 iaa-header w-100',
          tdClass: 'text-end align-middle py-1'
        },
        {
          label: 'Analyst Recommendation',
          key: 'recommend',
          thStyle: { minWidth: '190px' },
          thClass: 'fw-400 text-nowrap iaa-header',
          tdClass: 'py-1'
        }
      ]
    }
  },
  async fetch () {
    await Promise.allSettled([
      this.getHomeConsensusStockOverall(),
      this.getHomeConsensusTFEXOverall()
    ])
    this.loading = false
  },
  computed: {
    ...mapGetters({
      consensus: 'home/getHomeConsensusStockOverall',
      consensusTfex: 'home/getHomeConsensusTFEXOverall'
    }),
    consensusData () {
      const data = []
      const consensus = this.consensus && this.consensus.overall ? this.consensus.overall : []
      const marketTime = this.consensus && this.consensus.marketTime ? this.consensus.marketTime : new Date()
      this.$emit('consensusMarketTime', marketTime)
      consensus.slice(0, 5).forEach((item, index) => {
        data.push({
          symbol: item.symbol,
          lastPrice: item.lastPrice,
          totalCoverage: item.totalCoverage,
          buy: item.buy,
          hold: item.hold,
          sell: item.sell,
          recommendType: item.recommendType === '-' ? 'none-recomme' : item.recommendType,
          recommendTypeDisplay: item.recommendType === '-' ? '-' : item.recommendType.charAt(0).toUpperCase() + item.recommendType.slice(1),
          medianTargetPrice: item.medianTargetPrice,
          averageTargetPrice: item.averageTargetPrice,
          bullish: item.bullish,
          bearish: item.bearish,
          rangeData: [
            {
              key: 'buy',
              label: 'Buy',
              value: item.buy,
              bgColor: '#17AF54'
            },
            {
              key: 'hold',
              label: 'Hold',
              value: item.hold,
              bgColor: '#DC9B1B'
            },
            {
              key: 'sell',
              label: 'Sell',
              value: item.sell,
              bgColor: '#EC3E3E'
            }
          ]
        })
      })
      return data
    },
    consensusTfexData () {
      const data = []
      const consensusTfex = this.consensusTfex && this.consensusTfex.tfexOverall ? this.consensusTfex.tfexOverall : []
      const marketTime = this.consensus && this.consensus.marketTime ? this.consensus.marketTime : new Date()
      this.$emit('consensusMarketTime', marketTime)
      consensusTfex.slice(0, 5).forEach((item, index) => {
        data.push({
          symbol: item.symbol,
          lastPrice: item.lastPrice,
          totalCoverage: item.totalCoverage,
          longSide: item.longSide,
          shortSide: item.shortSide,
          recommendType: item.recommendType === '-' ? 'none-recomme' : item.recommendType,
          recommendTypeDisplay: item.recommendType === '-' ? '-' : item.recommendType.charAt(0).toUpperCase() + item.recommendType.slice(1),
          supportFuturesPrice: item.supportFuturesPrice,
          resistantFuturesPrice: item.resistantFuturesPrice,
          bullish: item.bullish,
          bearish: item.bearish,
          rangeData: [
            {
              key: 'buy',
              label: 'Long',
              value: item.longSide,
              bgColor: '#17AF54'
            },
            {
              key: 'sell',
              label: 'Short',
              value: item.shortSide,
              bgColor: '#EC3E3E'
            }
          ]
        })
      })
      return data
    }
  },
  methods: {
    formatDateShort,
    ...mapActions({
      getHomeConsensusStockOverall: 'home/getHomeConsensusStockOverall',
      getHomeConsensusTFEXOverall: 'home/getHomeConsensusTFEXOverall'
    }),
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
  }
}
