//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
// import dayjs from 'dayjs'
import { formatNumber } from '@/helpers/number'
import { openTab } from '~/helpers'
import { formatDateShort, dayjs as _dayjs } from '@/helpers/dateTime'
function convertFormatDate (datetime) {
  const d = _dayjs(datetime)
  // const y = d.year() + 543
  const date = d.format()
  return date
}
export default {
  name: 'HomeStockComparison',
  data () {
    return {
      topRanking: {
        info: [],
        chart: {}
      },
      listTopRank: [],
      listSearchData: [],
      loading: true,
      selectedDropdown: 'mostActiveVolume',
      dropdownItem: [
        { name: 'Top 5 Most Active Volume', value: 'mostActiveVolume' },
        { name: 'Top 5 Most Active Value', value: 'mostActiveValue' },
        { name: 'Top 5 Gainer', value: 'topGainer' },
        { name: 'Top 5 Loser', value: 'topLoser' },
        { name: 'Top 5 Popular Quote', value: 'popular' }
      ],
      statusCustomize: false,
      chartData: [],
      legendList: [],
      chartColorConstance: [
        '#16B916',
        '#85D63E',
        '#3FC1C0',
        '#106FDC',
        '#0C4484'
      ]
    }
  },
  // async fetch () {
  //   await this.$axios.get('api/set/stock/list', {
  //     params: {
  //       securityType: 's'
  //     }
  //   }).then((res) => {
  //     this.listSearchData = res.data.securitySymbols
  //   }).catch(err => err)
  // },
  computed: {
    ...mapState({
      _topRanking: state => state.home.stock.topRanking
    }),
    lastUpdate () {
      let asOfDate = null
      if (this.chartData.length) {
        const date = new Date(((this.chartData ?? [])[0] ?? {}).date)
        const dateBcYear = date
        const inputDate = new Date(dateBcYear).toLocaleString('en-EN', {
          dateStyle: 'short',
          timeStyle: undefined
        })
        asOfDate = inputDate
      }
      return asOfDate
    }
  },
  // async created () {
  //   await this.selectedTopRank('mostActiveVolume')
  // },
  async mounted () {
    await this.$axios
      .get('api/set/stock/list', {
        params: {
          securityType: 's'
        }
      })
      .then((res) => {
        this.listSearchData = res.data.securitySymbols
      })
      .catch(err => err)
    await this.selectedTopRank('mostActiveVolume')
  },
  methods: {
    formatNumber,
    formatDateShort,
    ...mapActions({
      getTopFive: 'home/stock/getTop5Data',
      getStockTopFiveIntradayChart: 'home/stock/getStockTop5IntradayChart'
    }),
    isCustomize () {
      if (!this.statusCustomize) {
        this.resetTopRankking()
        this.statusCustomize = false
        setTimeout(() => {
          this.statusCustomize = true
        }, 1) // for force rerender
      }
    },
    setloading (el) {
      this.loading = el
    },
    selectedTopRank (topRank) {
      if (topRank) {
        const rankingType = topRank
        this.selectedDropdown = topRank
        this.setloading(true)
        this.statusCustomize = false
        const payload = { rankingType }
        this.setTopFiveData(payload)
      }
    },
    async setTopFiveData (payload) {
      // await this.getTopFive(payload)
      const res = await this.getTopFive(payload)

      if (res && res.stocks && res.stocks.length > 0 && !this.statusCustomize) {
        this.topRanking.chart = this._topRanking.chart
        this.topRanking.info = res.stocks
        // this.topRanking.info.forEach((item) => {
        //   this.setIntradayChart({ quote: item.symbol })
        // })
        this.setListTopRank()
        setTimeout(() => {
          this.setChartData()
          this.setloading(false)
        }, 100) // for force rerender
      } else {
        this.setloading(false)
      }
    },
    async setIntradayChart (quote) {
      const res = await this.getStockTopFiveIntradayChart(quote)
      this.topRanking.chart[res.symbol] = res.chart
    },
    setListTopRank () {
      if (this.topRanking.info && this.topRanking.info.length > 0) {
        this.listTopRank = this.topRanking.info.map(e => e.symbol)
      }
    },
    setChartData () {
      const chart = this.topRanking.chart
      this.legendList = []
      for (const type in chart) {
        this.legendList.push(type)
      }
      const quotationList = []
      for (const type in chart) {
        const quotations = [...chart[type].quotations]
        quotationList.push({
          legend: type,
          quotations: quotations.reverse()
        })
      }
      this.chartData = this.findSymbolMaxLength(quotationList).quotations?.map(
        (item, index) => {
          // const d = _dayjs(item.datetime)
          // const y = d.year() + 543
          // const date = d.set('year', y).format()
          const date = convertFormatDate(item.datetime)
          const quotationGroup = { date }
          // const quotationGroup = {
          //   date: item.datetime
          // }
          for (let i = 0; i < this.legendList.length; i++) {
            const legend = this.legendList[i]
            const quotation = quotationList[i].quotations[index]
            quotationGroup[legend] = quotation
              ? Number(quotation.price.toFixed(2))
              : null
          }
          return quotationGroup
        }
      )
      // console.log('🚀 ~  this.chartData:', this.chartData)
    },
    async updateQuote (el) {
      const stockInfo = this.listSearchData.find((data) => {
        if (data && el) {
          return data.symbol
            .trim()
            .toUpperCase()
            .startsWith(el.trim().toUpperCase())
        }
        return false
      })
      if (stockInfo) {
        this.setloading(true)
        await this.setIntradayChart({ quote: stockInfo.symbol })
        this.topRanking.info.push({ symbol: stockInfo.symbol })
        this.setListTopRank()
        await this.setChartData()
        this.setloading(false)
      }
    },
    initRemoveCustomize (payload) {
      const { quote } = payload
      this.setloading(true)
      if (quote) {
        delete this.topRanking.chart[quote]
        this.topRanking.info = this.topRanking.info.filter(
          item => item.symbol !== quote
        )
        if (this.topRanking.info.length > 0) {
          this.setListTopRank()
          setTimeout(() => {
            this.setChartData()
            this.setloading(false)
            this.statusCustomize = true
          }, 100) // for force rerender
        } else {
          this.resetTopRankking()
          this.setloading(false)
        }
      }
    },
    gotoStockComparison () {
      const url = 'equities/stock-comparison'
      const params = new URLSearchParams()
      if (this.statusCustomize) {
        params.set('customize', this.statusCustomize)
        if (this.legendList && this.legendList.length > 0) {
          params.set('stock', this.legendList)
        }
      } else {
        params.set('rankingType', this.selectedDropdown)
      }
      openTab(url + '?' + params)
    },
    resetTopRankking () {
      this.topRanking = {
        info: [],
        chart: {}
      }
      this.chartData = []
      this.listTopRank = []
      this.legendList = []
    },
    onClickTopRanking () {
      if (this.statusCustomize) {
        this.resetTopRankking()
        this.selectedTopRank('mostActiveVolume')
      }
    },
    findSymbolMaxLength (chartData) {
      let maxLength = 0
      let maxLengthIndex = 0
      chartData.forEach((item, index) => {
        if (item.quotations.length > maxLength) {
          maxLength = item.quotations.length
          maxLengthIndex = index
        }
      })
      return chartData[maxLengthIndex]
    },
    handleClickTracking () {
      if (this.statusCustomize) {
        const symbolList =
          this.listTopRank && this.listTopRank.length > 0
            ? this.listTopRank.join(',')
            : '-'
        this.$personalized.clickElement({
          name: 'home29',
          optionLang: {
            buttonText: this.$t('buttons.viewMore'),
            symbolList
          }
        })
      } else {
        const category = this.dropdownItem.find(
          e => e.value === this.selectedDropdown
        ).name
        this.$personalized.clickElement({
          name: 'home28',
          optionLang: {
            buttonText: this.$t('buttons.viewMore'),
            category
          }
        })
      }
    }
  }
}
