import { render, staticRenderFns } from "./StatisticalData.vue?vue&type=template&id=5657a986&"
import script from "./StatisticalData.vue?vue&type=script&lang=js&"
export * from "./StatisticalData.vue?vue&type=script&lang=js&"
import style0 from "./StatisticalData.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconClock: require('/app/components/Icon/Clock.vue').default,TableCustomField: require('/app/components/Table/TableCustomField.vue').default,Table: require('/app/components/Table/Table.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default})
