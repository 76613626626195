//
//
//

const componentPath = new Map()
// AGRO
componentPath.set('AGRO', () =>
  import('@/components/Icon/IndustrySector/Agro/Agro.vue')
)
componentPath.set('AGRI', () =>
  import('@/components/Icon/IndustrySector/Agro/Agri.vue')
)
componentPath.set('FOOD', () =>
  import('@/components/Icon/IndustrySector/Agro/Food.vue')
)
// CONSUMP
componentPath.set('CONSUMP', () =>
  import('@/components/Icon/IndustrySector/Consump/Consump.vue')
)
componentPath.set('FASHION', () =>
  import('@/components/Icon/IndustrySector/Consump/Fashion.vue')
)
componentPath.set('HOME', () =>
  import('@/components/Icon/IndustrySector/Consump/Home.vue')
)
componentPath.set('PERSON', () =>
  import('@/components/Icon/IndustrySector/Consump/Person.vue')
)
// FINCIAL
componentPath.set('FINCIAL', () =>
  import('@/components/Icon/IndustrySector/Fincial/Fincial.vue')
)
componentPath.set('BANK', () =>
  import('@/components/Icon/IndustrySector/Fincial/Bank.vue')
)
componentPath.set('FIN', () =>
  import('@/components/Icon/IndustrySector/Fincial/Fin.vue')
)
componentPath.set('INSUR', () =>
  import('@/components/Icon/IndustrySector/Fincial/Insur.vue')
)
// INDUS
componentPath.set('INDUS', () =>
  import('@/components/Icon/IndustrySector/Indus/Indus.vue')
)
componentPath.set('AUTO', () =>
  import('@/components/Icon/IndustrySector/Indus/Auto.vue')
)
componentPath.set('IMM', () =>
  import('@/components/Icon/IndustrySector/Indus/Imm.vue')
)
componentPath.set('PAPER', () =>
  import('@/components/Icon/IndustrySector/Indus/Paper.vue')
)
componentPath.set('PETRO', () =>
  import('@/components/Icon/IndustrySector/Indus/Petro.vue')
)
componentPath.set('PKG', () =>
  import('@/components/Icon/IndustrySector/Indus/Pkg.vue')
)
componentPath.set('STEEL', () =>
  import('@/components/Icon/IndustrySector/Indus/Steel.vue')
)
// PROPCON
componentPath.set('PROPCON', () =>
  import('@/components/Icon/IndustrySector/Propcon/Propcon.vue')
)
componentPath.set('CONMAT', () =>
  import('@/components/Icon/IndustrySector/Propcon/Conmat.vue')
)
componentPath.set('PROP', () =>
  import('@/components/Icon/IndustrySector/Propcon/Prop.vue')
)
componentPath.set('PFANDREIT', () =>
  import('@/components/Icon/IndustrySector/Propcon/PfANDReit.vue')
)
componentPath.set('CONS', () =>
  import('@/components/Icon/IndustrySector/Propcon/Cons.vue')
)
// RESOURC
componentPath.set('RESOURC', () =>
  import('@/components/Icon/IndustrySector/Resourc/Resourc.vue')
)
componentPath.set('ENERG', () =>
  import('@/components/Icon/IndustrySector/Resourc/Energ.vue')
)
componentPath.set('MINE', () =>
  import('@/components/Icon/IndustrySector/Resourc/Mine.vue')
)
// SERVICE
componentPath.set('SERVICE', () =>
  import('@/components/Icon/IndustrySector/Service/Service.vue')
)
componentPath.set('COMM', () =>
  import('@/components/Icon/IndustrySector/Service/Comm.vue')
)
componentPath.set('HELTH', () =>
  import('@/components/Icon/IndustrySector/Service/Helth.vue')
)
componentPath.set('MEDIA', () =>
  import('@/components/Icon/IndustrySector/Service/Media.vue')
)
componentPath.set('PROF', () =>
  import('@/components/Icon/IndustrySector/Service/Prof.vue')
)
componentPath.set('TOURISM', () =>
  import('@/components/Icon/IndustrySector/Service/Tourism.vue')
)
componentPath.set('TRANS', () =>
  import('@/components/Icon/IndustrySector/Service/Trans.vue')
)
// TECH
componentPath.set('TECH', () =>
  import('@/components/Icon/IndustrySector/Tech/Tech.vue')
)
componentPath.set('ETRON', () =>
  import('@/components/Icon/IndustrySector/Tech/Etron.vue')
)
componentPath.set('ICT', () =>
  import('@/components/Icon/IndustrySector/Tech/Ict.vue')
)

const defaultComponent = () => import('@/components/Icon/IndustrySector/NoIcon.vue')
export default {
  name: 'IconIndustrySector',
  props: {
    industry: {
      type: String,
      default: ''
    },
    sector: {
      type: String,
      default: ''
    }
  },
  computed: {
    icon () {
      if (this.sector) {
        const info = componentPath.get(this.getkey(this.sector))
        return info || defaultComponent
      } else {
        const info = componentPath.get(this.getkey(this.industry))
        return info || defaultComponent
      }
    }
  },
  methods: {
    getkey (value) {
      return value.replace('&', 'AND')
    }
  }
}
