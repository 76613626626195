//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'Chart2HomeStockCompare',
  props: {
    chartLegend: {
      type: Array,
      default: Array
    },
    chartData: {
      type: Array,
      default: Array
    },
    listTopRank: {
      type: Array,
      default: Array
    },
    listSearchData: {
      type: Array,
      default: Array
    },
    statusCustomize: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chart: null,
      series: [],
      loading: true,
      isMobile: this.$device.isMobile,
      chartColorIntance: ['#16B916', '#3FC1C0', '#0C4484', '#ECB329', '#EC3E3E']
    }
  },

  computed: {
    renderChartData () {
      return cloneDeep(this.chartData || [])
    },
    symbolList () {
      return this.listTopRank || []
    }
  },

  watch: {
    renderChartData (val) {
      if (this.chart) {
        this.chart.dispose()
        this.init()
      }
    },
    chartLegend (val) {
      if (this.chart) {
        this.chart.dispose()
        this.init()
      }
    },
    statusCustomize () {
      this.series = []
    }
  },

  mounted () {
    setTimeout(() => {
      this.init()
    }, 500)
  },

  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },

  methods: {
    init () {
      const { am5, am5xy } = require('@/plugins/amchart5')

      const root = am5.Root.new(this.$refs.HomeStockCompareChartDiv)

      // root.autoResize = false

      // root.setThemes([am5ThemesAnimated.new(root)])

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          // paddingRight: 3,
          // paddingLeft: 3,
          maxTooltipDistance: -1,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
          paddingLeft: 0
        })
      )

      chart.zoomOutButton.set('forceHidden', true)

      chart.chartContainer.children.unshift(
        am5.Label.new(root, {
          text: 'Price Performance (%)',
          fontSize: 12,
          textAlign: 'right',
          x: am5.percent(100),
          marginBottom: 15,
          fill: am5.color('#72767D')
        })
      )

      /* Add data */
      const data = this.renderChartData
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map(item => ({ ...item, date: new Date(item.date).getTime() }))

      // <------------------ xAxis -----------> //

      const tooltipAxis = am5.Tooltip.new(root, {})

      tooltipAxis.get('background').setAll({
        fill: am5.color('#1D3352'),
        stroke: am5.color('#1D3352'),
        fillOpacity: 1
      })

      tooltipAxis.label.setAll({
        paddingTop: -4,
        paddingBottom: -2
      })

      // year format thai tooltip date
      tooltipAxis.label.adapters.add('text', function (text, target) {
        if (text) {
          const date = text.split('/')
          if (date?.length) {
            const yearFormat = (Number(date[date.length - 1]))
            return text.slice(0, -2) + (yearFormat + 2543).toString().substr(2, 2)
          }
        }
        return text
      })

      const xRenderer = am5xy.AxisRendererX.new(root, {
        stroke: am5.color('#545557'),
        strokeOpacity: 1,
        strokeWidth: 1,
        minGridDistance: 20
      })

      xRenderer.grid.template.setAll({
        // location: 0,
        visible: false
      })

      xRenderer.ticks.template.setAll({
        strokeOpacity: 1,
        visible: true,
        length: 5,
        minPosition: 0.05,
        maxPosition: 0.95
      })

      xRenderer.labels.template.setAll({
        // location: 0.0001,
        fill: am5.color('#72767D'),
        fontSize: 12,
        paddingTop: 10,
        minPosition: 0.05,
        maxPosition: 0.95
        // marginTop: 10
      })

      const gridIntervals = [
        // { timeUnit: 'day', count: 1 },
        // { timeUnit: 'day', count: 2 },
        // { timeUnit: 'day', count: 3 },
        // { timeUnit: 'day', count: 4 },
        // { timeUnit: 'day', count: 5 },
        // { timeUnit: 'day', count: 7 },
        // { timeUnit: 'month', count: 1 },
        { timeUnit: 'month', count: 2 }
      ]

      if (this.isMobile) {
        gridIntervals.push({ timeUnit: 'month', count: 3 })
      }

      const xAxis = chart.xAxes.push(
        am5xy.GaplessDateAxis.new(root, {
          maxDeviation: 0.1,
          // startLocation: 0,
          // endLocation: 10,
          // categoryField: 'date',
          tooltipDateFormat: 'dd/MM/yy',

          baseInterval: {
            timeUnit: 'day',
            count: 1
          },

          gridIntervals,
          tooltip: tooltipAxis,
          renderer: xRenderer
        })
      )

      xAxis.get('dateFormats').month = 'dd/MM/yy'
      xAxis.get('periodChangeDateFormats').month = 'dd/MM/yy'

      // <------------------ yAxis -----------> //

      const yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: true
      })

      yRenderer.labels.template.setAll({
        fill: am5.color('#72767D'),
        textAlign: 'right',
        fontSize: 12
      })

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yRenderer
        })
      )

      yAxis.set(
        'tooltip',
        am5.Tooltip.new(root, {
          forceHidden: true
        })
      )

      // <------------------ cursor -----------> //

      chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none',
          snapToSeriesBy: 'x',
          xAxis
        })
      )

      chart.appear(1000, 100)

      this.rerenderSeries(root, chart, am5, am5xy, xAxis, yAxis, data)

      // year format thai dateX
      xAxis.get('renderer').labels.template.adapters.add('text', function (text, target) {
        if (text) {
          const date = text.split('/')
          if (date?.length) {
            const yearFormat = (Number(date[date.length - 1]))
            return text.slice(0, -2) + (yearFormat + 2543).toString().substr(2, 2)
          }
        }
        return text
      })
      this.chart = root
    },

    async rerenderSeries (root, chart, am5, am5xy, xAxis, yAxis, data) {
      if (data.length) {
        chart.series.clear()

        await this.chartLegend.forEach((legend, index) => {
          let tooltipHTML =
            '<div class="d-flex flex-column tooltip-custom overflow-hidden" style="width:180px;">'
          this.chartLegend.forEach((legend, index) => {
            tooltipHTML += `<div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <div class="circle me-2" style="background-color:${this.chartColorIntance[index]};"></div>
                <div class="title-font-family fs-18px text-middle-gray">${legend}</div>
              </div>
              <div data-value="{${legend}}" class="title-font-family fs-18px tooltips-detail-value">{${legend}}%</div>
            </div>`
          })
          tooltipHTML += '</div>'

          const tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            autoTextColor: false,
            pointerOrientation: 'horizontal',
            labelHTML: tooltipHTML
          })

          tooltip.get('background').setAll({
            fill: am5.color('#ffffff'),
            fillOpacity: 0.95,
            stroke: am5.color('#DEE4E8'),
            strokeWidth: 0
          })
          this.series[index] = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: legend,
              xAxis,
              yAxis,
              valueXField: 'date',
              valueYField: legend,
              stroke: am5.color(this.chartColorIntance[index]),
              showTooltipOn: 'always'
            })
          )

          this.series[index].strokes.template.setAll({
            strokeWidth: 2
          })

          this.series[index].data.setAll(data)
          this.series[index].appear(1000)

          this.series[index].set('tooltip', tooltip)

          this.series[index]
            .get('tooltip')
            .label.adapters.add('text', function (fill, target) {
              const tooltipEle = document.querySelectorAll(
                '.tooltips-detail-value'
              )
              if (tooltipEle.length > 0) {
                tooltipEle.forEach((ele) => {
                  const dataset = ele.dataset
                  if (Number(dataset.value) > 0) {
                    ele.classList.add('text-success')
                  } else if (Number(dataset.value) < 0) {
                    ele.classList.add('text-danger')
                  } else {
                    ele.classList.add('text-dark')
                    if (ele.innerHTML === '%') {
                      ele.innerHTML = '-'
                    }
                  }
                })
              }
            })
        })
        // this.series[0].set('tooltip', tooltip)

        // this.series[0]
        //   .get('tooltip')
        //   .label.adapters.add('text', function (fill, target) {
        //     const tooltipEle = document.querySelectorAll(
        //       '.tooltips-detail-value'
        //     )
        //     if (tooltipEle.length > 0) {
        //       tooltipEle.forEach((ele) => {
        //         const dataset = ele.dataset
        //         if (Number(dataset.value) > 0) {
        //           ele.classList.add('text-success')
        //         } else if (Number(dataset.value) < 0) {
        //           ele.classList.add('text-danger')
        //         } else {
        //           ele.classList.add('text-dark')
        //           if (ele.innerHTML === '%') {
        //             ele.innerHTML = '-'
        //           }
        //         }
        //       })
        //     }
        //   })
      }
    },

    removeSeries (series, el) {
      if (this.statusCustomize) {
        if (this.series.length > 0) {
          // this.chartColorIntance.push(series.stroke.hex)
          // this.chart.series
          //   .removeIndex(this.chart.series.indexOf(series))
          //   .dispose()
          // this.series = this.chart.series.values
          this.$emit('removeIsCustomize', { quote: el })
        }
      } else {
        this.$emit('removeIsCustomize', { quote: el, status: true })
      }
    },

    clearSeries () {
      if (this.series.length > 0) {
        this.series.forEach((item) => {
          this.removeSeries(item)
        })
      }
    },
    selectQuote (quote) {
      this.$emit('selectQuote', quote)
    }
  }
}
