import { render, staticRenderFns } from "./StockScreening.vue?vue&type=template&id=65371faa&"
import script from "./StockScreening.vue?vue&type=script&lang=js&"
export * from "./StockScreening.vue?vue&type=script&lang=js&"
import style0 from "./StockScreening.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconOutstandingDividend: require('/app/components/Icon/OutstandingDividend.vue').default,IconArrowRightShort: require('/app/components/Icon/ArrowRightShort.vue').default,Button: require('/app/components/Button/Button.vue').default,IconGoodBasis: require('/app/components/Icon/GoodBasis.vue').default,IconScreeningGrowth: require('/app/components/Icon/ScreeningGrowth.vue').default,SwiperCarousel: require('/app/components/SwiperCarousel/SwiperCarousel.vue').default,IconSliders: require('/app/components/Icon/Sliders.vue').default,IconArrowRightLong: require('/app/components/Icon/ArrowRightLong.vue').default})
