//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconPieChart'
}
