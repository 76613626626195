//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { integerStyle, handleImageError } from '@/helpers'
import { formatNumber } from '@/helpers/number'
export default {
  name: 'HomeListedCompanyFirstTradingDay',
  filters: {
    industryFormat (value) {
      if (!value) {
        return '-'
      } else if (value.sectorName) {
        return `${value.industryName} / ${value.sectorName}`
      } else {
        return value.industryName
      }
    }
  },
  data () {
    return {
      firstTradingDay: null,
      loading: true,
      isDesktop: true,
      isMobile: false,
      swiperOption: {
        showPagination: false,
        showNavigation: true,
        showNavigationOutSide: true,
        resizeObserver: true,
        centeredSlides: true,
        pagination: {
          el: '.first-trade-day .swiper-pagination',
          // dynamicBullets: true,
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"></span>'
          }
        },
        navigation: {
          nextEl: '.first-trade-day .swiper-btn-next',
          prevEl: '.first-trade-day .swiper-btn-prev'
        },
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 100,
        breakpoints: {}
      }
    }
  },
  computed: {
    items () {
      return this.firstTradingDay ?? []
    },
    swiper () {
      return this.$refs.firstTradeCarousel.swiper
    },
    isDesktopScreen () {
      return this.isDesktop
    },
    viewMore () {
      return this.localePath(`/equities/stock/quote/${this.symbol}/overview`)
    }
  },
  async mounted () {
    await this.getFirstTrade()
    // this.firstTradingDay.push({
    //   symbol: 'COCOCO',
    //   securityType: '',
    //   change: 12,
    //   last: 100,
    //   percentChange: 3,
    //   logoUrl: '/images/logo/defaultImg.png',
    //   market: 'SET',
    //   name: 'COCONUT',
    //   businessType: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available',
    //   firstTradeDate: '2023-1-1',
    //   ipoPrice: 99,
    //   marketCap: 20000000,
    //   issuedSize: 100000000,
    //   pe: 99
    // })
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    this.$nextTick(() => {
      this.initTooltip()
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    integerStyle,
    formatNumber,
    handleImageError,
    async getFirstTrade () {
      this.firstTradingDay = await this.$axios
        .$get('/api/set/ipo/firstday', {
          params: {
            lang: this.$i18n.locale
          }
        })
        .then((res) => {
          return res
        })
        .catch(err => err)
      this.loading = false
    },
    initTooltip () {
      const { Tooltip } = require('bootstrap')
      const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      )
      tooltipTriggerList.forEach(
        tooltipTriggerEl => new Tooltip(tooltipTriggerEl, {
          customClass: 'custom-tooltip'
        }))
    },
    onResize () {
      const screenSize = window.innerWidth
      if (screenSize > 1024) {
        this.isDesktop = true
      } else {
        this.isDesktop = false
        if (screenSize < 768) {
          this.isMobile = true
        } else {
          this.isMobile = false
        }
      }
    },
    symbolRoute (symbol, securityType) {
      const urlString = this.$quote.getQuoteUrl({
        symbol,
        securityType
      })
      return urlString
    },
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
  }
}
