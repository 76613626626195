import { render, staticRenderFns } from "./IndexSet.vue?vue&type=template&id=0b18f7b2&scoped=true&"
import script from "./IndexSet.vue?vue&type=script&lang=js&"
export * from "./IndexSet.vue?vue&type=script&lang=js&"
import style0 from "./IndexSet.vue?vue&type=style&index=0&id=0b18f7b2&lang=scss&scoped=true&"
import style1 from "./IndexSet.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b18f7b2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/app/components/NavTabs/Tab.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,IconLineChart: require('/app/components/Icon/LineChart.vue').default,IconBarChart: require('/app/components/Icon/BarChart.vue').default,IconPieChart: require('/app/components/Icon/PieChart.vue').default,Mouse: require('/app/components/Mouse/Mouse.vue').default,HomeMarketSummaryIndex: require('/app/components/Pages/Home/MarketSummaryIndex.vue').default,TabPane: require('/app/components/NavTabs/TabPane.vue').default,HomeInvestorsTypeSummary: require('/app/components/Pages/Home/InvestorsTypeSummary.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,HomeStatisticalData: require('/app/components/Pages/Home/StatisticalData.vue').default,TabContent: require('/app/components/NavTabs/TabContent.vue').default,HomeFTSESET: require('/app/components/Pages/Home/FTSESET.vue').default,HomeTriIndex: require('/app/components/Pages/Home/TriIndex.vue').default,HomeTfex: require('/app/components/Pages/Home/Tfex/Tfex.vue').default,HomeGlobal: require('/app/components/Pages/Home/Global/Global.vue').default})
