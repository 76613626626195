//
//
//
//

export default {
  layout: 'home',
  async asyncData ({ store }) {
    // const payload = {
    //   market: 'SET',
    //   rankingType: 'mostActiveVolume',
    //   securityType: 'S',
    //   count: 5,
    //   tab: 'set'
    // }
    store.dispatch('home/setLoadingChart', true)
    await Promise.allSettled([
      // store.dispatch('home/getMarketHome', payload),
      // store.dispatch('home/getMarketHomeChart', payload),
      // store.dispatch('home/getMarketHomeMostActive', payload),
      // store.dispatch('home/getMarketInfoUnderHomeChart', payload)
    ])
  }
}
