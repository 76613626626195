//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconScreeningGrowth'
}
