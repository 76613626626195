//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { formatDateShort } from '@/helpers/dateTime'
import { numberColor, openTab } from '@/helpers/index'
import { formatNumberInteger, formatNumberPercent } from '@/helpers/number'
export default {
  name: 'HomeTriIndex',
  props: {
    market: {
      type: String,
      default: 'SET'
    }
  },
  data () {
    return {
      selectItem: {
        symbol: '',
        tri: 0,
        change: 0,
        percentChange: 0
      },
      chartData: {
        prior: 0,
        quotations: []
      }
    }
  },
  computed: {
    ...mapGetters({
      triIndex: 'home/getTriIndex'
    }),
    date () {
      return this.triIndex?.date ?? ''
    },
    items () {
      const data = this.triIndex?.data ?? []
      if (this.market === 'SET') {
        return data.filter((item) => {
          return item.symbol !== 'mai'
        })
      } else {
        return data.filter((item) => {
          return item.symbol === 'mai'
        })
      }
    }
  },
  mounted () {
    this.$nextTick(async () => {
      const data = this.triIndex?.data ?? []
      this.selectItem = data.filter((item) => {
        return item.symbol === this.market
      })[0]
      await this.getChartData()
    })
  },
  methods: {
    formatDateShort,
    numberColor,
    formatNumberInteger,
    formatNumberPercent,
    openTab,
    activeStyle (change) {
      if (change < 0) {
        return 'active-negative'
      } else {
        return 'active-positive'
      }
    },
    async selectWithItem (item) {
      this.selectItem = item
      await this.getChartData()
    },
    async getChartData () {
      try {
        const response = await this.$axios.$get(`api/set/tri/${this.selectItem.symbol}/chart-quotation?period=1M`)
        this.chartData = response
      } catch {
        this.chartData = {}
      }
    },

    eventTrackingReadMore () {
      this.$personalized.clickElement({
        name: 'home8',
        optionLang: {
          market: this.market,
          title: `${this.market} TRI`
        }
      })
    }
  }
}
