import { render, staticRenderFns } from "./MarketSummaryIndex.vue?vue&type=template&id=6f47ecff&"
import script from "./MarketSummaryIndex.vue?vue&type=script&lang=js&"
export * from "./MarketSummaryIndex.vue?vue&type=script&lang=js&"
import style0 from "./MarketSummaryIndex.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardMarketIndex: require('/app/components/Card/CardMarketIndex.vue').default,ChartLoading: require('/app/components/Chart/Loading.vue').default,Chart2LineMarket: require('/app/components/Chart2/LineMarket.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,IconTriangle: require('/app/components/Icon/Triangle.vue').default,InputRangeMulti: require('/app/components/Input/RangeMulti.vue').default,Button: require('/app/components/Button/Button.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default})
