//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BProgress, BProgressBar } from 'bootstrap-vue'
export default {
  name: 'InputRangeMulti',
  components: {
    BProgress,
    BProgressBar
  },
  props: {
    rangeData: {
      type: Array,
      default: () => [
        {
          key: 'Buy',
          value: 14,
          rangeClass: '',
          bgColor: '#17AF54'
        },
        {
          key: 'Hold',
          value: 1,
          rangeClass: '',
          bgColor: '#DC9B1B'
        },
        {
          key: 'Sell',
          value: 1,
          rangeClass: '',
          bgColor: '#EC3E3E'
        }
      ]
    },
    isCustomLabel: {
      type: Boolean
    },
    progressClass: {
      type: String,
      default: 'my-2'
    }
  },
  computed: {
    rangeTotal () {
      return this.rangeData.reduce((a, c) => a + c.value, 0)
    },
    rangeProgressBar () {
      return this.rangeData.filter(x => x.value > 0)
    }
  }
}
