var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[(_vm.loading)?[_c('Placeholder',{staticClass:"ratio ratio-16x9 w-100 mb-1 rounded h-100"})]:_c('div',{staticClass:"analyst h-100"},[_c('div',{staticClass:"head d-flex flex-row align-items-start justify-content-between"},[_vm._m(0),_vm._v(" "),_c('MiscEquityTabButtonGroup',{staticClass:"mb-3 mb-md-0",attrs:{"tabs":_vm.tabs},on:{"input":function($event){return _vm.$emit('market', $event)}}})],1),_vm._v(" "),_c('div',{staticClass:"table-analyst-wrapper mb-auto"},[_c('TabContent',{staticClass:"most-active-volume-tab-content"},[_c('TabPane',{staticClass:"h-100",attrs:{"id":"iaa-consensus-set","active":true}},[_c('TableCustomField',{staticClass:"table-analyst pb-0 p-xl-0",attrs:{"fields":_vm.fields,"items":_vm.consensusData},scopedSlots:_vm._u([{key:"cell(product)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex td-product"},[_c('div',{staticClass:"content d-flex flex-column"},[_c('div',{staticClass:"symbol title-font-family head-text fw-bold",on:{"click":function($event){return _vm.handleClickTracking('home50', {market: 'SET', symbol:item.symbol})}}},[_c('ButtonQuote',{staticClass:"btn-wrap text-start",attrs:{"btn-quote-data":{
                        symbol: item.symbol,
                        pathMatch: 'analyst-consensus'
                      },"is-new-tab":true}})],1),_vm._v(" "),_c('span',{staticClass:"last-update fs-12px text-nowrap ms-4"},[_vm._v("\n                    ราคาล่าสุด "+_vm._s(_vm._f("formatNumber")(item.lastPrice))+"\n                  ")])])])]}},{key:"cell(target)",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"td-target"},[_vm._v("\n                "+_vm._s(_vm._f("formatNumber")(item.medianTargetPrice))+"\n              ")])]}},{key:"cell(recommend)",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"td-recommend row mx-0"},[_c('div',{staticClass:"col-3 p-2 ps-0"},[_c('div',{staticClass:"status px-1 text-center text-nowrap",class:item.recommendType.toLowerCase()},[_vm._v("\n                    "+_vm._s(item.recommendTypeDisplay)+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"col-9 ps-1 analyst-input-range text-nowrap"},[_c('InputRangeMulti',{attrs:{"range-data":item.rangeData}})],1)])]}}])})],1),_vm._v(" "),_c('TabPane',{staticClass:"h-100",attrs:{"id":"iaa-consensus-tfex"}},[_c('TableCustomField',{staticClass:"table-analyst pb-0 p-xl-0",attrs:{"fields":_vm.fieldsTfex,"items":_vm.consensusTfexData},scopedSlots:_vm._u([{key:"head(target)",fn:function(ref){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("ราคาฟิวเจอร์ส")]),_vm._v(" "),_c('span',[_vm._v("(แนวรับ - แนวต้าน) *")])])]}},{key:"cell(product)",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex td-product"},[_c('div',{staticClass:"content d-flex flex-column"},[_c('div',{staticClass:"symbol title-font-family head-text fw-bold",on:{"click":function($event){return _vm.handleClickTracking('home50', {market: 'TFEX', symbol:item.symbol})}}},[_c('ButtonQuote',{staticClass:"btn-wrap text-start",attrs:{"btn-quote-data":{
                        symbol: item.symbol,
                        pathMatch: 'analyst-consensus'
                      },"is-new-tab":true}})],1),_vm._v(" "),_c('span',{staticClass:"last-update fs-12px text-nowrap ms-4"},[_vm._v("\n                    ราคาล่าสุด "+_vm._s(_vm._f("formatNumber")(item.lastPrice))+"\n                  ")])])])]}},{key:"cell(target)",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"td-target"},[_vm._v("\n                "+_vm._s(_vm._f("formatNumber")(item.supportFuturesPrice))+" - "+_vm._s(_vm._f("formatNumber")(item.resistantFuturesPrice))+"\n              ")])]}},{key:"cell(recommend)",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"td-recommend row mx-0"},[_c('div',{staticClass:"col-4 p-2 ps-0"},[_c('div',{staticClass:"status px-1 text-center text-nowrap",class:item.recommendType.toLowerCase()},[_vm._v("\n                    "+_vm._s(item.recommendTypeDisplay)+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"col-8 ps-1 analyst-input-range text-nowrap"},[_c('InputRangeMulti',{attrs:{"range-data":item.rangeData}})],1)])]}},{key:"empty",fn:function(){return [_vm._t("empty",function(){return [_c('Message',{staticClass:"border-0",attrs:{"msg-title":"ไม่มีข้อมูล"}})]})]},proxy:true}],null,true)})],1)],1)],1),_vm._v(" "),_vm._t("default")],2)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heading1-font-family d-flex flex-column flex-md-row align-items-baseline"},[_c('div',{staticClass:"d-flex flex-column flex-md-row me-md-2 me-lg-0"},[_c('h4',{staticClass:"title-header heading1-font-family m-0 p-0"},[_vm._v("\n            ผลสำรวจความเห็น\n          ")]),_vm._v(" "),_c('h4',{staticClass:"title-header heading1-font-family m-0 p-0"},[_vm._v("\n            นักวิเคราะห์\n            "),_c('span',{staticClass:"title-header-by ms-0"},[_vm._v(" by IAA ")])])])])}]

export { render, staticRenderFns }