//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DropdownItem',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSelect () {
      this.$refs.inputCheck.checked = true
    }
  }
}
