//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { openTab } from '~/helpers'
export default {
  name: 'NewsAndArticle',
  data () {
    return {
      loadingNews: true,
      loadingArticle: true,
      investmentNews: [],
      knowledgeArticle: [],
      swiperOptionNews: {
        // showPagination: true,
        navigation: false,
        showNavigation: false,
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        grabCursor: true,
        resizeObserver: true,
        pagination: {
          el: '.news-and-article  .swiper-pagination-news-card',
          // dynamicBullets: true,
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"><div class="circle"></div></span>'
          }
        }
      },
      swiperOptionArticle: {
        // showPagination: true,
        navigation: false,
        showNavigation: false,
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        grabCursor: true,
        resizeObserver: true,
        pagination: {
          el: '.news-and-article  .swiper-pagination-articles',
          // dynamicBullets: true,
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"><div class="circle"></div></span>'
          }
        }
      }
    }
  },
  async fetch () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      const requestInvestmentNews = this.$apiCms.get('/api/cms/v1/news/cms/investment', {
        params: {
          lang: this.$i18n.local,
          sources: 'CMS,RSS',
          skip: 0,
          take: 12
        }
      })

      const requestKnowledgeArticle = this.$apiCms.get('/api/cms/v1/knowledge/articles', {
        params: {
          language: this.$i18n.local,
          pageIndex: 0,
          pageSize: 12
        }
      })

      const requestList = await Promise.all([
        requestInvestmentNews,
        requestKnowledgeArticle
      ])

      this.investmentNews = _.chunk(_.orderBy(requestList[0].data, ['pined', 'publishDate'], ['desc', 'desc']), 4)
      this.knowledgeArticle = _.chunk(_.orderBy(requestList[1].data.items, ['pined', 'publishDate'], ['desc', 'desc']), 4)
      this.loadingNews = false
      this.loadingArticle = false
    },
    initSwiper (swiper) {
      // bug mobile fires resize event on scroll
      let oldWidth = window.innerWidth
      swiper.on('resize', () => {
        const newWidth = window.innerWidth
        if (oldWidth !== newWidth) {
          oldWidth = window.innerWidth
        }
      })
    },
    getImage (thumbnail) {
      if (thumbnail) {
        const images = thumbnail.filter((r) => { return r.size === 'default' })[0]
        return images?.url
      }
      return null
    },
    onClickViewMore (section) {
      openTab(`news-and-articles/${section}/main`)
      this.addEventTracking('home42', { section, buttonText: this.$t('buttons.viewMore') })
    },
    addEventTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
  }
}
