//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { formatDateShort, formatDateTimeLong } from '@/helpers/dateTime'
import { formatNumber } from '@/helpers/number'
// import { openTab } from '~/helpers'
export default {
  name: 'HomeMarketMovementSlide',

  data () {
    return {
      nvdrTab: 'netBuy',
      swiperOption: {
        showPagination: false,
        showNavigation: true,
        showNavigationOutSide: true,
        watchOverflow: true,
        loop: false,
        spaceBetween: 1,
        pagination: {
          el: '.market-movement-slide .swiper-pagination-movement',
          // dynamicBullets: true,
          clickable: true,
          renderBullet: (index, className) => {
            return (
              '<span class="' +
              className +
              '"><div class="circle"></div></span>'
            )
          }
        },
        navigation: {
          nextEl: '.carousel-movement .swiper-btn-next',
          prevEl: '.carousel-movement .swiper-btn-prev'
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        breakpoints: {
          768: {
            slidesPerView: 2,
            slidesPerGroup: 1
          },
          992: {
            slidesPerView: 2,
            slidesPerGroup: 1
          },
          1200: {
            slidesPerView: 3,
            slidesPerGroup: 1
          }
        }
      }
    }
  },

  async fetch () {
    await this.getHomeMarketMovement()
  },

  computed: {
    ...mapGetters({
      marketMovement: 'home/market-movement/getMarketMovement'
    }),

    nvdrBuy () {
      return this.marketMovement.nvdr.buy || []
    },

    nvdrSell () {
      return this.marketMovement.nvdr.sell || []
    },

    shortSales () {
      return this.marketMovement.shortSales.data || []
    },

    bigLot () {
      return this.marketMovement.bigLot.data || []
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.initTooltip()
    })
  },

  updated () {
    this.$nextTick(() => {
      this.initTooltip()
    })
  },

  destroyed () {
    this.destroyTooltip()
  },

  methods: {
    formatDateShort,
    formatDateTimeLong,
    formatNumber,

    ...mapActions({
      getHomeMarketMovement: 'home/market-movement/getHomeMarketMovement'
    }),

    selectNvdrTab (tabType) {
      this.nvdrTab = tabType
    },

    initTooltip () {
      const { Tooltip } = require('bootstrap')
      const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      )
      tooltipTriggerList.forEach(
        tooltipTriggerEl => new Tooltip(tooltipTriggerEl, {
          customClass: 'custom-tooltip'
        }))
    },

    destroyTooltip () {
      const { Tooltip } = require('bootstrap')
      const tooltipTriggerList =
        document.querySelectorAll('[data-bs-toggle="tooltip"]') || []
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        const tooltipEle = Tooltip.getOrCreateInstance(tooltipTriggerEl)
        tooltipEle.dispose()
      })
    },

    goMarketData (page) {
      let detailUrl = null
      if (page === 'nvdr') {
        detailUrl = this.localePath({
          path: 'equities/market-data/nvdr/value?tab=' + this.nvdrTab
        })
      }
      if (page === 'BigLot') {
        detailUrl = this.localePath({
          path: 'equities/market-data/biglot'
        })
      }
      if (page === 'ShortSell') {
        detailUrl = `https://www.set.or.th/${this.$i18n.locale}/market/statistics/short-sales/total-short-sales`
      }
      return detailUrl
    },
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
  }
}
