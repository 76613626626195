//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { formatNumber } from '@/helpers/index'
import { openTab } from '~/helpers'
export default {
  name: 'MarketSummaryIndex',
  props: {
    marketSummaryData: {
      type: Object,
      default: Object
    },
    market: {
      type: String,
      default: 'SET'
    },
    indexTab: {
      type: String,
      default: 'SET'
    }
  },
  computed: {
    ...mapGetters({
      marketHomeChart: 'home/getMarketHomeChart',
      marketInfoUnderChart: 'home/getMarketInfoUnderHomeChart',
      loadingChart: 'home/getLoadingChart',
      marketChartNow: 'home/getMarketChartNow'
    }),
    hasNightSession () {
      return this.marketSummaryData?.hasNightSession ?? false
    }
  },
  methods: {
    formatNumber,
    rangeData () {
      let result = []
      if (this.marketInfoUnderChart) {
        const dataInfo = this.marketInfoUnderChart
        result = [
          {
            key: 'gainers',
            label: 'Gainers',
            value: dataInfo.gainerAmount,
            rangeClass: 'theme-success px-0 fix_col',
            bgColor: '#17AF54'
          },
          {
            key: '',
            label: '',
            value: 0,
            class_bar: 'border-0',
            rangeClass: 'line_Y',
            bgColor: '#fff'
          },
          {
            key: 'unchanges',
            label: 'Unchanges',
            value: dataInfo.unchangedAmount,
            rangeClass: 'theme-normal px-0 fix_col',
            bgColor: '#DC9B1B'
          },
          {
            key: '',
            label: '',
            value: 0,
            class_bar: 'border-0',
            rangeClass: 'line_Y',
            bgColor: '#fff'
          },
          {
            key: 'losers',
            label: 'Losers',
            value: dataInfo.loserAmount,
            rangeClass: 'theme-danger px-0 fix_col',
            bgColor: '#EC3E3E'
          }
        ]
      }
      return result
    },
    goMarketSETOverview (market) {
      openTab('equities/market-data/overview?category=Index&index=' + market)
    },
    goMarketMaiOverview (market) {
      openTab(
        'equities/market-data/overview?market=mai&category=Index&index=' +
          market
      )
    },
    goMarketSummary (market) {
      openTab('equities/market-summary/overview?market=' + market)
    },
    gotoTfexOverview () {
      openTab('derivatives/overview')
    },

    eventTrackingReadMore () {
      if (this.market === 'TFEX') {
        this.$personalized.clickElement({
          name: 'home12',
          optionLang: {
            market: this.indexTab
          }
        })
      } else {
        this.$personalized.clickElement({
          name: 'home5',
          optionLang: {
            market: this.indexTab
          }
        })
      }
    }
  }
}
