//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import { formatDateTimeLong } from '@/helpers/dateTime'
export default {
  name: 'MarketTimeUpdateDateInfo',
  data () {
    return {
      marketDateTime: Date()
    }
  },
  async fetch () {
    await this.fetchData()
  },
  computed: {
    ...mapState('summary', {
      _marketDateTime: state => state.marketDateTime
    })
  },
  methods: {
    formatDateTimeLong,
    ...mapActions('summary', [
      'getMarketDateTime'
    ]),
    async fetchData () {
      await this.getMarketDateTime()
      this.marketDateTime = this._marketDateTime.marketDateTime
    }
  }
}
