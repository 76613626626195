import { render, staticRenderFns } from "./upcomingIPO.vue?vue&type=template&id=49f62335&"
import script from "./upcomingIPO.vue?vue&type=script&lang=js&"
export * from "./upcomingIPO.vue?vue&type=script&lang=js&"
import style0 from "./upcomingIPO.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MiscEquityCompanyLogo: require('/app/components/Misc/Equity/CompanyLogo.vue').default,Card: require('/app/components/Card/Card.vue').default,IconDocument: require('/app/components/Icon/Document.vue').default,IconBoxArrowUpRight2: require('/app/components/Icon/BoxArrowUpRight2.vue').default,IconWorld: require('/app/components/Icon/World.vue').default,IconArrowRightShort: require('/app/components/Icon/ArrowRightShort.vue').default,SwiperCarousel: require('/app/components/SwiperCarousel/SwiperCarousel.vue').default,Message: require('/app/components/Message/index.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default,ModalVideo: require('/app/components/Modal/Video.vue').default,Placeholder: require('/app/components/Placeholder/Placeholder.vue').default,PlaceholderList: require('/app/components/Placeholder/List.vue').default})
