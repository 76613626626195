//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { integerStyle, formatNumber } from '@/helpers/index'
import { formatDateTimeLong } from '@/helpers/dateTime'
import { convertKeyRankingTypeToPath } from '@/helpers/utilities'

export default {
  name: 'HomeMostActiveVolume',
  data () {
    return {
      indexTab: 'SET',
      mostActiveType: 'mostActiveVolume',
      loading: false
    }
  },
  async fetch () {
    const payload = {
      market: 'SET',
      rankingType: 'mostActiveVolume',
      securityType: 'S',
      count: 10,
      tab: 'set'
    }
    await this.$store.dispatch('home/getMarketHomeMostActive', payload)
  },
  computed: {
    ...mapGetters({
      marketHomeMostActive: 'home/getMarketHomeMostActive'
    }),
    mostActiveData () {
      return this.marketHomeMostActive ? this.marketHomeMostActive.stocks : []
    },
    marketDateTime () {
      return this.marketHomeMostActive ? this.marketHomeMostActive : []
    }
  },
  // mounted () {
  //   const payload = {
  //     market: 'SET',
  //     rankingType: 'mostActiveVolume',
  //     securityType: 'S',
  //     count: 5,
  //     tab: 'set'
  //   }
  //   this.$store.dispatch('home/getMarketHomeMostActive', payload)
  // },
  methods: {
    integerStyle,
    formatNumber,
    formatDateTimeLong,
    convertKeyRankingTypeToPath,
    async changeIndexTab (market) {
      this.loading = true
      this.indexTab = market
      const payload = {
        market,
        rankingType: this.mostActiveType,
        securityType: 'S',
        count: 10
      }
      await this.$store.dispatch('home/getMarketHomeMostActive', payload)
      this.loading = false
    },
    async dropdownSelected (value) {
      this.loading = true
      this.mostActiveType = value
      const payload = {
        market: this.indexTab,
        rankingType: value,
        securityType: 'S',
        count: 10
      }
      await this.$store.dispatch('home/getMarketHomeMostActive', payload)
      this.loading = false
    },
    goMarketSummary () {
      let rankingType = ''
      switch (this.mostActiveType) {
        case 'mostActiveVolume':
        case 'mostActiveValue':
        case 'topGainer':
        case 'topLoser':
          rankingType = 'overview'
          break
        default:
          rankingType = this.convertKeyRankingTypeToPath(this.mostActiveType)
          break
      }
      const route = this.$router.resolve({
        path:
          'equities/market-summary/top-ranking/' +
          rankingType +
          `?${rankingType === 'overview' ? 'market' : 'markettab'}=` +
          this.indexTab
      })
      window.open(route.href)
    },
    formatRankingPeriod (el) {
      const rankingPeriod = el.rankingPeriod?.split('/') ?? []
      // // const newMonth = rankingPeriod.replace(/[0-9]{4}/g, '')
      // // const newYear =
      // // parseInt(rankingPeriod.replace(/[0-9]{1}[A-Z]\//g, '')) + 543
      return rankingPeriod[0] + '/' + (parseInt(rankingPeriod[1]) + 543)
    },
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    },
    peroidRemark (mostActiveType) {
      return mostActiveType === 'topEPS' ||
            mostActiveType === 'topNetProfitMargin' ||
            mostActiveType === 'topROE' ||
            mostActiveType === 'topROA'
    },
    getRankingTypeText () {
      switch (this.mostActiveType) {
        case 'mostActiveVolume':
          return 'Most Active Volume'
        case 'mostActiveValue':
          return 'Most Active Value'
        case 'topPE':
          return 'Top P/E'
        case 'topDividendYield':
          return 'Top Dividend Yield'
        case 'topEPS':
          return 'Top EPS'
        case 'topNetProfitMargin':
          return 'Top Net Profit MarginTop'
        case 'topROE':
          return ' Top ROE'
        case 'topROA':
          return 'Top ROA'
        case 'topGainer':
          return 'Top Gainer'
        case 'topLoser':
          return 'Top Loser'
        case 'popular':
          return 'Popular Quote'
      }
    }
  }
}
