//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
import { mapGetters, mapActions } from 'vuex'
import { contentRenderType } from '@/utils'
export default {
  name: 'Breadcrumb',
  props: {
    items: {
      type: Array,
      default: null
    },
    align: {
      type: String,
      default: ''
    },
    pathStructure: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true
    }
  },
  async fetch () {
    await this.loadBreadcrumbData()
  },
  computed: {
    ...mapGetters({
      content: 'contents/getContentData',
      pageBreadcrumb: 'contents/getPageBreadcrumbData', // get by path structure
      renderType: 'contents/getRenderTypeData'
    }),
    isPageBuilder () {
      return this.renderType === contentRenderType.pageBuilder
    },
    breadcrumbItems () {
      const firstItem = [
        {
          text: this.$t('home'),
          href: this.localePath('/home'),
          begin: true
        }
      ]

      let newBreadcrumbs = []

      // Get breadcrumb items from content
      const content = cloneDeep(this.content)
      const items = this.items ?? []
      const pageBreadcrumb = JSON.parse(
        JSON.stringify(this.pageBreadcrumb)
      ).map((r) => {
        return {
          text: r.title,
          href: r.url
        }
      })
      let breadcrumbs = [...pageBreadcrumb, ...items] // items || parentItems
      if (this.isPageBuilder === true) {
        const parentItems = (content.body?.parentBreadcrumbs || []).map((r) => {
          return {
            text: r.title,
            href: r.url
          }
        })
        breadcrumbs = parentItems
      }

      // Current page
      const hasItems = items.length > 0
      let currentPage = []
      if (this.isPageBuilder === true && hasItems === false) {
        currentPage = [
          {
            text: content.head.title,
            active: true
          }
        ]
      }
      newBreadcrumbs = [...firstItem, ...breadcrumbs, ...currentPage]
      newBreadcrumbs = newBreadcrumbs.map((item, i) => {
        item.start = i === 0
        item.beforeEnd =
          newBreadcrumbs.length > 2 && i === newBreadcrumbs.length - 2
        item.active = i === newBreadcrumbs.length - 1
        return {
          ...item
        }
      })
      // console.log('[debug] breadcrumbs', newBreadcrumbs)
      return newBreadcrumbs
    },
    /* breadcrumbItems () {
      const firstItem = [{
        text: this.$t('home'),
        href: this.localePath('/home'),
        begin: true
      }]

      let newBreadcrumbs = []

      // Get breadcrumb items from content
      const content = cloneDeep(this.content)
      if (content.body) {
        const parentItems = (content.body.parentBreadcrumbs || []).map((r) => {
          return {
            text: r.title,
            href: r.url
          }
        })

        const items = this.items
        const breadcrumbs = items || parentItems
        const hasItems = (items || []).length

        // Current page
        let currentPage = []
        if (!hasItems) {
          currentPage = [{
            text: content.head.title,
            active: true
          }]
        }
        newBreadcrumbs = [...firstItem, ...breadcrumbs, ...currentPage]
      } else {
        newBreadcrumbs = [...firstItem]
      }
      newBreadcrumbs = newBreadcrumbs.map((item, i) => {
        item.start = i === 0
        item.beforeEnd = newBreadcrumbs.length > 2 && i === (newBreadcrumbs.length - 2)
        item.end = i === (newBreadcrumbs.length - 1)
        return {
          ...item
        }
      })
      return newBreadcrumbs
    }, */
    alignClass () {
      let className = ''
      switch (this.align) {
        case 'center':
          className = 'justify-content-center'
          break
        case 'right':
          className = 'justify-content-end'
          break
        default:
          className = ''
      }
      return className
    }
  },
  // created () {
  //   if (process.client) {
  //     this.loadBreadcrumbData()
  //   }
  // },
  // created () {
  //   this.loadBreadcrumbData()
  // },
  methods: {
    ...mapActions({
      getPageBreadcrumb: 'contents/getPageBreadcrumb'
    }),
    async loadBreadcrumbData () {
      let path = '' // this.getCurrentPathStructure()
      const _pathStructure = this.pathStructure ?? ''
      // console.log('Path structure', _pathStructure)
      if (!_pathStructure) {
        this.loading = false
        return
      }
      if (_pathStructure) {
        path = `/${this.$i18n.locale}${_pathStructure}`
      }
      await this.getPageBreadcrumb(path)
      this.loading = false
    }
  }
}
