//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 10)
export default {
  name: 'Dropdown',
  props: {
    display: {
      type: String,
      default: 'dynamic'
    },
    onSelected: {
      type: Function,
      default: () => { }
    },
    dropdownToggleClass: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      nanoid: null,
      dropdownId: null,
      dropdownToggle: null,
      dropdownToggleId: null,
      selectedValue: null,
      dropdownItems: [],
      dropdownItem: null
    }
  },
  created () {
    this.dropdownItems = this.$children
  },
  mounted () {
    this.init()
    this.$nextTick(() => {
      this.registerEvent()
      this.defaultSelectedItem()
    })
  },
  beforeDestroy () {
    this.$nextTick(() => {
      this.removeEvent()
    })
  },
  methods: {
    init () {
      const { Dropdown } = require('bootstrap')
      const options = {
        display: this.display
      }
      const elementDropdownToggle = this.$refs.dropdownToggle
      this.dropdownToggle = new Dropdown(elementDropdownToggle, options)

      // set dropdown id
      const id = nanoid()
      this.dropdownId = `dropdown${id}`
      this.dropdownToggleId = `dropdownToggle${id}`

      // set item attribute
      this.$nextTick(() => {
        this.setItemAttribute()
      })
    },
    setItemAttribute () {
      const dropdownItems = this.dropdownItems
      dropdownItems.forEach((item) => {
        const element = item.$el
        if (item.value) {
          element.dataset.value = item.value
        }
      })
      const inputCheck = this.$refs.dropdown.querySelectorAll('.input-check')
      inputCheck.forEach((element) => {
        if (element) {
          element.setAttribute('name', `input${this.dropdownId}`)
        }
      })
    },
    registerEvent () {
      const dropdownItems = this.dropdownItems
      dropdownItems.forEach((item) => {
        const element = item.$el
        element.addEventListener('click', this.handleSelectItem)
      })
    },
    removeEvent () {
      const dropdownItems = this.dropdownItems
      dropdownItems.forEach((item) => {
        const element = item.$el
        element.removeEventListener('click', this.handleSelectItem)
      })
    },
    handleSelectItem (event) {
      const dropdownItem = event.currentTarget
      this.selectItem(dropdownItem)

      // do callback
      const value = dropdownItem.dataset.value
      this.onSelected(value)
    },
    selectItem (dropdownTtem) {
      if (!dropdownTtem) { return }
      const dropdownTtemInner = dropdownTtem.querySelector('.dropdown-item-inner')
      if (dropdownTtemInner) {
        const html = dropdownTtemInner.innerHTML
        this.$refs.dropdownToggleInner.innerHTML = html
      }
    },
    defaultSelectedItem () {
      // select item
      const selectedItem = this.dropdownItems.filter(item => item.selected)[0] || {}
      this.selectItem(selectedItem.$el)
    }
  }
}
