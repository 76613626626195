//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { getFavouriteCategoryType } from '@/helpers/favourite/helpers'
import { isTypeStockSubR } from '@/helpers/utilities'
export default {
  name: 'ButtonQuote',
  props: {
    isUsefFavorite: {
      type: Boolean,
      default: true
    },
    btnQuoteData: {
      type: Object,
      default: () => ({
        securities: '',
        symbol: '',
        securityType: 'S',
        sign: '',
        pathMatch: 'overview'
      })
    },
    highlight: {
      type: Boolean,
      default: false
    },
    isNewTab: {
      type: Boolean,
      default: false
    },
    isAccordion: {
      type: Boolean,
      default: false
    },
    isAnalystTab: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      securityType: '',
      optionsType: undefined,
      symbolHighlight: '',
      href: ''
    }
  },
  computed: {
    ...mapGetters({
      stocks: 'search/getStocks'
    }),
    favourite () {
      return this.$favourite?.state ?? {}
    },
    isActive () {
      if ('active' in this.btnQuoteData) {
        const { active } = this.btnQuoteData
        return active === true
      }
      return !this.isTypeStockSubR(this.btnQuoteData, this.securityType) && this.href !== '#'
    },
    isFavorite () {
      const category = getFavouriteCategoryType(
        this.btnQuoteData.securityType ?? this.securityType
      )
      const favorite = this.favourite[category] ?? []
      return favorite.includes(this.btnQuoteData.symbol)
    }
  },
  watch: {
    btnQuoteData (newValue, oldValue) {
      // for on change table page index
      if (newValue && oldValue && newValue?.symbol !== oldValue?.symbol) {
        this.symbolRoute()
      }
    }
  },
  mounted () {
    this.symbolRoute()
  },
  methods: {
    isTypeStockSubR,
    setTabUrl (paths, symbols) {
      this.$emit('onClickSymbol', symbols)
      if (this.isAccordion) {
        window.open(paths, '_blank')
      }
    },
    async onFavorite (event) {
      this.$emit('clickFavorite')
      event.stopPropagation()
      const payload = {
        symbol: this.btnQuoteData.symbol,
        category: getFavouriteCategoryType(
          this.btnQuoteData.securityType ?? this.securityType
        )
      }
      const isLogin = await this.$authUser.isLogin()
      if (isLogin) {
        if (this.isFavorite) {
          const { Modal } = require('bootstrap')
          const myModalEl = document.querySelector('#modalDeleteFavourite')
          const modal = Modal.getOrCreateInstance(myModalEl)
          modal.show()
          this.$store.commit('member/favourite/setDelete', payload)
        } else {
          const result = await this.$favourite.addFavourite(
            payload.category,
            payload.symbol
          )

          if (result.status === 201) {
            this.$toast.success(
              'success',
              'เพิ่มหลักทรัพย์ลงรายการที่ติดตามแล้ว'
            )
          } else if (result.status === 400 && result.message === 'List full') {
            this.$favourite.openModalFavSymbolFull()
          } else {
            // in other cases like Duplicate symbol or case status === 403 || 500
            this.$toast.fail('warning', 'เกิดข้อผิดพลาด')
          }
        }
      } else {
        this.$cookies.set('favorite-quote-remember', payload, { path: '/' })
        window.location.href = this.$authUser.login()
      }
    },
    setStockSecurityType () {
      const stockInfo = this.stocks.find((data) => {
        return data.symbol === this.btnQuoteData.symbol
      })
      if (stockInfo) {
        this.securityType = stockInfo.securityType
        this.optionsType = stockInfo.optionsType
      } else {
        this.securityType = ''
        this.optionsType = undefined
      }
    },
    symbolRoute () {
      if (!this.btnQuoteData.securityType) {
        this.setStockSecurityType()
      }
      let urlString = this.$quote.getQuoteUrl({
        ...this.btnQuoteData,
        securityType: this.btnQuoteData.securityType ?? this.securityType,
        optionsType: this.btnQuoteData.optionsType ?? this.optionsType
      })
      if (this.isAnalystTab) {
        const urlAnalyst = this.analystConsensusRoute()
        urlString = urlAnalyst.length > 0 ? urlAnalyst : urlString
      }
      this.href = urlString
    },
    analystConsensusRoute () {
      const { localePath } = this
      const btnQuoteData = this.btnQuoteData ? this.btnQuoteData : null
      if (btnQuoteData) {
        const symbol = btnQuoteData.securities || btnQuoteData.symbol
        return localePath(`/equities/quote/${symbol}/analyst-consensus`)
      }
      return ''
    }
  }
}
