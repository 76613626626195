//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeMarketMovement',
  data () {
    return {
      backgroundImage1: '/images/background/bg-right.png',
      backgroundImage2: '/images/background/bg-left1.png',
      backgroundImage3: '/images/background/bg-left2.png'
    }
  }
}
