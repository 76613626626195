//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Cover',
  props: {
    images: {
      type: Array,
      default: () => []
    },
    breadcrumb: {
      type: Boolean,
      default: true
    },
    pathStructure: {
      type: String,
      default: null
    }
  },
  methods: {
    handleImageLoaded () {
      const classList = [].slice.call(this.$refs.cover.classList)
      const className = 'cover-loaded'
      const hasClass = classList.includes(className)
      if (!hasClass) {
        this.$refs.cover.classList.add(className)
      }
    }
  }
}
