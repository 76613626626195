import { render, staticRenderFns } from "./NewsAndArticle.vue?vue&type=template&id=b4c6c396&"
import script from "./NewsAndArticle.vue?vue&type=script&lang=js&"
export * from "./NewsAndArticle.vue?vue&type=script&lang=js&"
import style0 from "./NewsAndArticle.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeNewsAndAnalystCardNews: require('/app/components/Pages/Home/NewsAndAnalyst/CardNews.vue').default,SwiperCarousel: require('/app/components/SwiperCarousel/SwiperCarousel.vue').default,Message: require('/app/components/Message/index.vue').default,PlaceholderList: require('/app/components/Placeholder/List.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default})
