//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { openTab } from '~/helpers'
export default {
  name: 'Analyst',
  data () {
    return {
      isMobile: this.$device.isMobile,
      show: true,
      currentTab: { tab: 'news', cate: '' },
      iaaMarket: 'SET',
      consensusMarketTimeVal: null
    }
  },
  mounted () {
    this.consensusMarketTime()
  },
  methods: {
    consensusMarketTime (val) {
      this.consensusMarketTimeVal = val
    },
    newTabIaaViewMore () {
      this.handleClickTracking('home51', { market: 'SET' })
      openTab('research/iaa-consensus/main')
    },
    newTabIaaTfexViewMore () {
      this.handleClickTracking('home51', { market: 'TFEX' })
      openTab('research/iaa-tfex-consensus/main')
    },
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
  }
}
