var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stock-screening bg-secondary"},[_vm._m(0),_vm._v(" "),_c('div',[_c('client-only',[_c('SwiperCarousel',{ref:"carouselScreening",staticClass:"mb-3 mb-md-4 carousel-screening position-relative",attrs:{"options":_vm.swiperOption,"image-loaded":true}},[_c('div',{staticClass:"swiper-slide carousel-cell"},[_c('div',{staticClass:"carousel-cell-inner rounded-2 bg-white"},[_c('IconOutstandingDividend',{staticClass:"icon d-block me-auto mb-2"}),_vm._v(" "),_c('a',{ref:"noopener noreferrer",staticClass:"\n                heading1-font-family\n                fs-20px\n                text-title text-start\n                cursor-pointer\n                text-decoration-none\n              ",attrs:{"target":"_blank","href":_vm.gotoStockScreening('dividend')},on:{"click":function($event){_vm.handleClickTracking('home30', {
                  contentTitle: 'ปันผลเด่น',
                  buttonText: _vm.$t('buttons.viewMore')
                })}}},[_vm._v("\n              ปันผลเด่น\n            ")]),_vm._v(" "),_c('p',{staticClass:"text-desc text-start fs-12px"},[_vm._v("\n              คัดหุ้นที่มีการจ่ายปันผลอย่างต่อเนื่อง\n            ")]),_vm._v(" "),_c('div',{staticClass:"text-start d-flex flex-nowrap"},_vm._l((_vm.dividendList),function(item,index){return _c('a',{key:'dividend-stock-' + index,staticClass:"\n                  btn-sm\n                  button-quote\n                  text-other-text-link text-decoration-none\n                  heading1-font-family\n                  px-1\n                  fs-16px\n                ",attrs:{"href":_vm.gotoQuoteOverview(item),"target":"_blank"},on:{"click":function($event){return _vm.handleClickTracking('home31', {
                    contentTitle: 'ปันผลเด่น',
                    symbol: item
                  })}}},[_vm._v("\n                "+_vm._s(item)+"\n              ")])}),0)],1),_vm._v(" "),_c('Button',{ref:"noopener noreferrer",staticClass:"\n              btn-lg btn-viewmore\n              d-flex\n              align-items-center\n              justify-content-center\n              position-relative\n              mx-auto\n              fs-18px\n              text-white\n              text-decoration-none\n            ",attrs:{"target":"_blank","href":_vm.gotoStockScreening('dividend')},on:{"click":function($event){_vm.handleClickTracking('home30', {
                contentTitle: 'ปันผลเด่น',
                buttonText: _vm.$t('buttons.viewMore')
              })}}},[_c('span',[_vm._v("\n              "+_vm._s(_vm.$t('buttons.viewMore'))+"\n            ")]),_vm._v(" "),_c('IconArrowRightShort',{staticClass:"fs-5",attrs:{"width":".7em","viewBox":"0 0 12 16"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"swiper-slide carousel-cell"},[_c('div',{staticClass:"carousel-cell-inner rounded-2 bg-white"},[_c('IconGoodBasis',{staticClass:"icon d-block me-auto mb-2"}),_vm._v(" "),_c('a',{ref:"noopener noreferrer",staticClass:"\n                heading1-font-family\n                fs-20px\n                text-title text-start\n                cursor-pointer\n                text-decoration-none\n              ",attrs:{"target":"_blank","href":_vm.gotoStockScreening('fundamental')},on:{"click":function($event){_vm.handleClickTracking('home30', {
                  contentTitle: 'พื้นฐานดี',
                  buttonText: _vm.$t('buttons.viewMore')
                })}}},[_vm._v("\n              พื้นฐานดี\n            ")]),_vm._v(" "),_c('p',{staticClass:"text-desc text-start fs-12px"},[_vm._v("\n              คัดหุ้นที่มีฐานะทางการเงินมั่นคง\n            ")]),_vm._v(" "),_c('div',{staticClass:"text-start d-flex flex-nowrap"},_vm._l((_vm.fundamentalList),function(item,index){return _c('a',{key:'fundamental-stock-' + index,staticClass:"\n                  btn-sm\n                  button-quote\n                  text-other-text-link text-decoration-none\n                  heading1-font-family\n                  fs-16px px-1\n                ",attrs:{"href":_vm.gotoQuoteOverview(item),"target":"_blank"},on:{"click":function($event){return _vm.handleClickTracking('home31', {
                    contentTitle: 'พื้นฐานดี',
                    symbol: item
                  })}}},[_vm._v("\n                "+_vm._s(item)+"\n              ")])}),0)],1),_vm._v(" "),_c('Button',{ref:"noopener noreferrer",staticClass:"\n              btn-lg btn-viewmore\n              d-flex\n              align-items-center\n              justify-content-center\n              position-relative\n              mx-auto\n              fs-18px\n              text-white\n              text-decoration-none\n            ",attrs:{"target":"_blank","href":_vm.gotoStockScreening('fundamental')},on:{"click":function($event){_vm.handleClickTracking('home30', {
                contentTitle: 'พื้นฐานดี',
                buttonText: _vm.$t('buttons.viewMore')
              })}}},[_c('span',[_vm._v("\n              "+_vm._s(_vm.$t('buttons.viewMore'))+"\n            ")]),_vm._v(" "),_c('IconArrowRightShort',{staticClass:"fs-5",attrs:{"width":".7em","viewBox":"0 0 12 16"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"swiper-slide carousel-cell"},[_c('div',{staticClass:"carousel-cell-inner rounded-2 bg-white"},[_c('IconScreeningGrowth',{staticClass:"icon d-block me-auto mb-2"}),_vm._v(" "),_c('a',{ref:"noopener noreferrer",staticClass:"\n                heading1-font-family\n                fs-20px\n                text-title text-start\n                cursor-pointer\n                text-decoration-none\n              ",attrs:{"target":"_blank","href":_vm.gotoStockScreening('growth')},on:{"click":function($event){_vm.handleClickTracking('home30', {
                  contentTitle: 'เติบโตต่อเนื่อง',
                  buttonText: _vm.$t('buttons.viewMore')
                })}}},[_vm._v("\n              เติบโตต่อเนื่อง\n            ")]),_vm._v(" "),_c('p',{staticClass:"text-desc text-start fs-12px"},[_vm._v("\n              คัดหุ้นที่มีอัตราการเติบโตของกำไรเพิ่มขึ้น\n            ")]),_vm._v(" "),_c('div',{staticClass:"text-start d-flex flex-nowrap"},_vm._l((_vm.growthList),function(item,index){return _c('a',{key:'growth-stock-' + index,staticClass:"\n                  btn-sm\n                  button-quote\n                  text-other-text-link text-decoration-none\n                  heading1-font-family\n                  px-1\n                  fs-16px\n                ",attrs:{"href":_vm.gotoQuoteOverview(item),"target":"_blank"},on:{"click":function($event){return _vm.handleClickTracking('home31', {
                    contentTitle: 'เติบโตต่อเนื่อง',
                    symbol: item
                  })}}},[_vm._v("\n                "+_vm._s(item)+"\n              ")])}),0)],1),_vm._v(" "),_c('Button',{ref:"noopener noreferrer",staticClass:"\n              btn-lg btn-viewmore\n              d-flex\n              align-items-center\n              justify-content-center\n              position-relative\n              mx-auto\n              fs-18px\n              text-white\n              text-decoration-none\n            ",attrs:{"target":"_blank","href":_vm.gotoStockScreening('growth')},on:{"click":function($event){_vm.handleClickTracking('home30', {
                contentTitle: 'เติบโตต่อเนื่อง',
                buttonText: _vm.$t('buttons.viewMore')
              })}}},[_c('span',[_vm._v("\n              "+_vm._s(_vm.$t('buttons.viewMore'))+"\n            ")]),_vm._v(" "),_c('IconArrowRightShort',{staticClass:"fs-5",attrs:{"width":".7em","viewBox":"0 0 12 16"}})],1)],1)])],1),_vm._v(" "),_c('div',{staticClass:"text-end"},[_c('a',{ref:"noopener noreferrer",staticClass:"btn-go-to-stock-filter heading-font-family text-white py-0 pe-0",attrs:{"target":"_blank","href":_vm.gotoStockScreening('customize')},on:{"click":function($event){return _vm.handleClickTracking('home32')}}},[_c('IconSliders',{staticClass:"me-1"}),_vm._v(" "),_c('span',{staticClass:"\n            btn-go-to-stock-filter-inner\n            d-inline-flex\n            align-items-center\n            fs-20px\n            py-1\n          "},[_c('span',{staticClass:"me-2"},[_vm._v(" คัดกรองหุ้นด้วยตัวคุณเอง ")]),_vm._v(" "),_c('IconArrowRightLong',{staticStyle:{"fill":"#fff"}})],1)],1)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-md-row align-items-baseline mb-2"},[_c('h3',{staticClass:"title text-white mb-0 me-2"},[_vm._v("\n      คัดกรองหุ้น\n    ")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('h4',{staticClass:"sub-title heading1-font-family fs-24px mb-0"},[_vm._v("\n        Stock Screening  \n      ")]),_vm._v(" "),_c('h4',{staticClass:"sub-title title-font-family fs-24px mb-0"},[_vm._v("\n        (Basic Guideline by IAA)\n      ")])])])}]

export { render, staticRenderFns }