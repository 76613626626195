/*
Version 4
import * as amCore from '@amcharts/amcharts4/core'
import * as amCharts from '@amcharts/amcharts4/charts'
import * as amRangeSelector from '@amcharts/amcharts4/plugins/rangeSelector'
import amThemesAnimated from '@amcharts/amcharts4/themes/animated'
import amLangTH from '@amcharts/amcharts4/lang/th_TH'

// amCore.options.deferredDelay = 0
// amCore.options.onlyShowOnViewport = true
// amCore.options.queue = true
// amCore.options.autoDispose = true

// chart license
// const CHART_LICENSE_V5 = 'AM5C311284907'
const CHART_LICENSE_V4 = 'CH311284907'
amCore.addLicense(CHART_LICENSE_V4)

export { amCore, amCharts, amRangeSelector, amThemesAnimated, amLangTH }
*/
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import * as am5stock from '@amcharts/amcharts5/stock'
// import am5ThemesAnimated from '@amcharts/amcharts5/themes/Animated'
import amLangTH from '@amcharts/amcharts5/locales/th_TH'

// chart license
const CHART_LICENSE_V5 = 'AM5C311284907'
am5.addLicense(CHART_LICENSE_V5)

export { am5, am5xy, am5stock, amLangTH }
