//
//
//
//

import { dayjs as _dayjs } from '@/helpers/dateTime'
export default {
  name: 'ChartLineTRI',
  props: {
    dataObj: {
      type: Object,
      default: Object
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    dataObj () {
      this.init()
    }
  },
  mounted () {
    this.init()
    setTimeout(() => {}, 500)
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },
  methods: {
    init () {
      const {
        amCore,
        amCharts
      } = require('~/plugins/amChart')
      const chart = amCore.create(this.$refs.chartdiv, amCharts.XYChart)

      chart.hiddenState.properties.opacity = 0
      chart.padding(10, -10, 0, 0)
      chart.zoomOutButton.disabled = true

      const data = this.dataObj.quotations.map((item) => {
        // return { ...item, datetime: new Date(item.datetime) }
        return { ...item, datetime: _dayjs(item.datetime).$d }
      })
      chart.data = data.sort(
        (a, b) => new Date(a.datetime) - new Date(b.datetime)
      )

      const dateAxis = chart.xAxes.push(new amCharts.DateAxis())
      dateAxis.renderer.minGridDistance = 40
      dateAxis.renderer.grid.template.location = 0
      dateAxis.renderer.ticks.template.length = 8
      dateAxis.renderer.ticks.template.disabled = false
      dateAxis.renderer.ticks.template.strokeOpacity = 1
      dateAxis.renderer.ticks.template.stroke = amCore.color('#1D3352')
      dateAxis.renderer.grid.template.stroke = amCore.color('#fff')
      dateAxis.renderer.line.strokeOpacity = 1
      dateAxis.renderer.line.strokeWidth = 1
      dateAxis.renderer.line.stroke = amCore.color('#1D3352')
      dateAxis.tooltip.background.fill = amCore.color('#1D3352')
      dateAxis.renderer.fontSize = '12px'
      // dateAxis.skipEmptyPeriods = true
      dateAxis.baseInterval = {
        timeUnit: 'day',
        count: 1
      }
      dateAxis.tooltipDateFormat = 'dd/MM/YY'
      dateAxis.dateFormats.setKey('day', 'dd/MM/yy')
      dateAxis.periodChangeDateFormats.setKey('day', 'dd/MM/yy')
      dateAxis.gridIntervals.setAll([{ timeUnit: 'day', count: 15 }])
      dateAxis.adapter.add('getTooltipText', function (text, target, key) {
        const date = text.split('/')
        const yearFormat = (Number(date[date.length - 1]) + 2543).toString().substr(2, 2)
        return text.slice(0, 6) + yearFormat
      })
      dateAxis.renderer.labels.template.adapter.add(
        'text',
        function (text, target) {
          chart.paddingLeft = 0
          if (typeof text === 'undefined') {
            chart.paddingLeft = 10
            return text
          } else if (isNaN(Number(text))) {
            chart.paddingLeft = 20
            // ม.ค. 2019
            if (text.length === 9) {
              const date = text.split(' ')
              return date[0] + ' ' + (Number(date[1]) + 543)
              // MM/yy
            } else if (text.length === 5) {
              const date = text.split('/')
              const yearFormat = (Number(date[date.length - 1]) + 2543).toString().substr(2, 2)
              return text.slice(0, 3) + yearFormat
              // ก.พ.
            } else if (text.length === 4) {
              chart.paddingLeft = 10
              return text
              // dd/MM/yy
            } else if (text.length === 8) {
              chart.paddingLeft = 30
              const date = text.split('/')
              const yearFormat = (Number(date[date.length - 1]) + 2543).toString().substr(2, 2)
              return text.slice(0, 6) + yearFormat
            }
            return text
          }
          return Number(text) + 543
        }
      )

      const valueAxis = chart.yAxes.push(new amCharts.ValueAxis())
      valueAxis.tooltip.disabled = true
      valueAxis.renderer.line.strokeOpacity = 0
      valueAxis.renderer.line.strokeWidth = 1
      valueAxis.renderer.line.stroke = amCore.color('#1D3352')
      valueAxis.renderer.opposite = true
      valueAxis.renderer.fontSize = '12px'
      // valueAxis.baseValue = prior

      valueAxis.adjustLabelPrecision = false
      valueAxis.numberFormatter = new amCore.NumberFormatter()
      valueAxis.numberFormatter.numberFormat = '#,###'

      const series = chart.series.push(new amCharts.LineSeries())
      series.dataFields.dateX = 'datetime'
      series.dataFields.valueY = 'price'
      series.defaultState.transitionDuration = 0
      series.strokeWidth = 2
      series.stroke = amCore.color('#0C4484')
      // series.tooltipHTML = tooltipHTML
      series.tooltipText = '{price}'
      series.tooltip.fontFamily = "'DB Helvethaica X', sans-serif"
      series.tooltip.pointerOrientation = 'down'
      series.tooltip.getFillFromObject = false
      series.tooltip.background.fill = amCore.color('white')
      series.tooltip.background.strokeWidth = 1
      series.tooltip.fontSize = '18px'
      series.tooltip.background.stroke = amCore.color('#FFFFFF')
      series.tooltip.label.fill = amCore.color('#292626')

      chart.cursor = new amCharts.XYCursor()
      chart.cursor.behavior = 'none'

      this.chart = chart
    }
  }
}
