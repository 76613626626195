//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { openTab } from '~/helpers'
import { getCateParam } from '@/helpers/categoryParams'
export default {
  name: 'CardNews',
  props: {
    item: {
      type: Object,
      default: Object
    },
    section: {
      type: String,
      default: String
    }
  },
  methods: {
    handleClick (clickTag = false) {
      if (this.section === 'news') {
        this.addEventTracking('home37', { section: 'ข่าวสาร', contentTitle: this.item.title })
        if (clickTag) {
          this.gotoNewsPage(this.item)
        } else {
          this.gotoNewsDetail(this.item)
        }
      } else {
        this.addPageView(this.item)
        this.addEventTracking('home37', { section: 'บทความ', contentTitle: this.item.title })
        if (clickTag) {
          this.gotoArticlePage(this.item)
        } else {
          openTab(this.item.url)
        }
      }
    },
    addEventTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    },
    async updatePageView (item) {
      const { newsSource, viewByCard, uuid } = item ?? {}
      if (viewByCard && newsSource === 'RSS') {
        await this.$apiCms.$post('/api/cms-w/v1/rss/news/view', {
          rssfeedDataUuid: uuid,
          clientUuid: this.$store.state.clientId,
          setMemberUserRef: parseInt(this.$authUser.userRef(), 10) || 0
        })
      }
      if (newsSource === 'CMS') {
        this.addPageView(item)
      }
    },
    gotoNewsDetail (item) {
      this.updatePageView(item)
      openTab(item.url, '_blank')
    },
    gotoNewsPage (item) {
      window.open(`/th/news-and-articles/news/main?keyword=&category=${getCateParam(item.cateUuid)}`, '_blank')
    },
    gotoArticlePage (item) {
      // window.open(`/th/news-and-articles/articles/main?category=${item.cateUuid}`, '_blank')
      if (item.cateUrl) {
        window.open(item.cateUrl, '_blank')
      }
    },
    addPageView (item) {
      const viewByCard = item?.viewByCard || false
      if (viewByCard === true) {
        const payload = {
          pageUuid: item.uuid
        }
        this.$content.addPageView(payload)
      }
    }
  }
}
