//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconTopEPS'
}
