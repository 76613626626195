//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDateDash } from '@/helpers/dateTime'
export default {
  name: 'CoverHome',
  serverCacheKey: () => `CoverHome::${formatDateDash(new Date())}`,
  computed: {
    isMobileOrTablet () {
      return this.$device.isMobileOrTablet
    },
    homeCoverImageCacheKey () {
      return `HomeCoverImage::${formatDateDash(new Date())}`
    }
  }
}
