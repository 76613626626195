//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconOutstandingDividend'
}
